import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { AuthAction } from '../../actions/AuthAction';
import { ProgramConstant } from '../../actions/constants/ProgramConstant';
import { ProgramAction } from '../../actions/ProgramAction';
import { Translate as t } from '../../config/Translate';
import { InputSelect2WithGroup } from './Inputs';
import { Modal } from 'react-bootstrap';

const AdminTopMenu = ({ user, dashboardtype, DashboardType, automapplay, showSub, showChart, chartType, companies, deviceCategory, SetDeviceCategory, Logout, clist, showmodel }) => {
	const location = useLocation()
	const [isDS, setIsDS] = useState(true);
	const [sm, setSM] = useState(['']);
	const [hideMenu, setHideMenu] = useState(false);
	const [modal, setModal] = useState({
		show : false
	});

	const dispatch = useDispatch();

	useEffect(() => {
		setIsDS(location.pathname == "/Admin/Dashboard");
	}, [location])

	useEffect(() => {
		setSM(showmodel);
	}, [showmodel])
	
	useEffect(() => {
		setHideMenu(user.companyId === 'RAY00016' && user.adminType === 'U')
	}, [user]);

	const getModels = () => {
		return [
			{ code : '', title : 'All Model' }, 
			...[...new Set(clist?.filter(x => x.type === 'PRODUCTOPTION').map(x => x.group))].map(x => ({ code : x, title : x }))
		];
	}

	const applyFilter = () => {
		dispatch({ type: ProgramConstant.SHOW_MODEL, showmodel : sm });
		setModal({...modal, show : false});
	}

	return (
		<div className="appHeader bg-primary">
			<div className="left">
				{
					!hideMenu &&
					<a className="headerButton" data-bs-toggle="modal"
						data-bs-target="#sidebarPanel"
						onClick={() => dispatch({ type: ProgramConstant.SIDEMENU_OPEN })}>
						<ion-icon name="menu-outline"></ion-icon>
					</a>
				}
				{companies.length == 1 && <span className='text-bold text-white' style={{ marginLeft: '20px' }}>{companies[0].name}</span>}				
				{(isDS && dashboardtype == "map") &&
					<>
					{
						!hideMenu &&
						<>
							<a href className="headerButton" data-bs-toggle="modal"
								data-bs-target="#sidebarPanel"
								onClick={() => dispatch({ type: ProgramConstant.SET_SHOWSUB, showSub: !showSub })}>
								{!showSub && <ion-icon name="chevron-forward-circle-outline"></ion-icon>}
								{showSub && <ion-icon name="chevron-down-circle-outline"></ion-icon>}
							</a>

							<a href className="headerButton"
								onClick={() => dispatch({
									type: ProgramConstant.SET_CHARTOPT,
									showChart: !showChart,
									chartType: chartType
								})}>
								{!showChart && <ion-icon name="podium-outline"></ion-icon>}
								{showChart && <ion-icon name="podium"></ion-icon>}
							</a>
							<a href className="headerButton"
								onClick={() => dispatch({
									type: ProgramConstant.SET_CHARTOPT,
									showChart: showChart,
									chartType: chartType == "err" ? "acq" : "err"
								})}>
								{chartType == "err" && <ion-icon name="alert-circle-outline"></ion-icon>}
								{chartType == "acq" && <ion-icon name="camera-outline"></ion-icon>}
							</a>
						</>
					}
					</>
				}
				<div className="pageTitle">
					<Link to="/Admin/Dashboard" className="text-white">RAYGuard</Link>
				</div>
			</div>
			<div className="right align-items-center">
				{
					<>
						<button className='btn btn-primary mt-0 me-2' type="button" style={{ height : 42}} onClick={() => setModal({...modal, show : true})}>
							{t('Filter')} {showmodel[0] !== '' ? '(' + showmodel.length + ')' : '' }
						</button>
					</>
				}
				{isDS && <div className="me-2">
					<span className="me-1 float-start">{t('map')}</span>
					<div className="form-check form-switch float-start" style={{ height: 24 }}>
						<input className="form-check-input" type="checkbox" checked={dashboardtype == "statistics"} onChange={() => DashboardType(dashboardtype == "statistics" ? "map" : "statistics")} />
						<label className="form-check-label" onClick={(e) => DashboardType(dashboardtype == "statistics" ? "map" : "statistics")} />						
					</div>
					<span className="ms-1">{t('statistics')}</span>
				</div>}
				{
					hideMenu &&
					<button className='btn btn-primary mt-0' type="button" style={{ height: '32px', marginRight: '20px', verticalAlign: 'middle', padding: '0 10px' }} onClick={() => Logout()}>
						{t('signout')}
					</button>
				}
			</div>			
			<Modal show={modal.show} onHide={() => setModal({...modal, show : false})}>
				<Modal.Header>
					<h5 className="modal-title">{t('Global filter')}</h5>
				</Modal.Header>
				<Modal.Body className=" p-2">
					<InputSelect2WithGroup options={{
						name: "showmodel",
						list: getModels(),
						isMulti : true,
						onlyitems : true,
						val: sm || [''],
					}} onchange={(e) => {
						let mm = ['']
						if(e.target.value.length > 0){
							if(e.target.value[e.target.value.length - 1] === ''){
								mm = [''];
							}else{
								mm = e.target.value.filter(n => n !== '')
							}
						}
						setSM(mm);
					}  } editable={true} style />
					<button className="btn btn-block btn-primary" data-bs-dismiss="modal" onClick={() => applyFilter()}>{t('apply')}</button>
				</Modal.Body>
			</Modal>
		</div>
	);
};

const mapState = (state) => {
	var loggingIn = state.AuthReducer.loggingIn;
	var dashboardtype = state.ProgramReducer.dashboardtype;
	var showSub = state.ProgramReducer.showSub;
	var showChart = state.ProgramReducer.showChart;
	var chartType = state.ProgramReducer.chartType;
	var automapplay = state.ProgramReducer.automapplay;
	var companies = state.CompanyReducer.companies;
	var deviceCategory = state.ProgramReducer.deviceCategory;
	var user = state.AuthReducer.user;
	const clist = state.RayCodeReducer.items;
	const showmodel = state.ProgramReducer.showmodel;

	return { user, loggingIn, dashboardtype, showSub, showChart, chartType, automapplay, companies, deviceCategory, clist, showmodel };
};

const mapDispatch = (dispatch) => ({
	Logout: () => dispatch(AuthAction.Logout()),
	DashboardType: (type) => dispatch(ProgramAction.DashboardType(type)),
	SetDeviceCategory: (category) => dispatch(ProgramAction.DeviceCategory(category))
});

export default connect(mapState, mapDispatch)(AdminTopMenu);
