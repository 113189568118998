import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { RayTable, AdminProductACQListHeader, RayChartBar } from '../common';
import { NoRecord } from '../../pages/HomePage';
import { Translate as t } from '../../config/Translate';

const ProductInfoACQ = ({ product, showtable = true, days = 14, height = 400, onlyGraph = false }) => {

	const isAdmin = location.pathname.toLowerCase().startsWith('/admin/');
	const [chartData, setChartData] = useState([]);
	const [hasChartData, setHasChartData] = useState(true);
	const [chartCategory, setChartCategory] = useState([]);
	const [chartFilter, setChartFilter] = useState({
		date: "", modality: ""
	});

	const options = {
		search: true,
		keyCol: "_id",
		filterColumns: [
			{ col: "confirm", val: "ALL" },
			{ col: "modality", val: "ALL" },
			{ col: "protocol", val: "ALL" }
		],
		filterpageoption: {
			filterDateOptions: [
				{ code: "activationTime", label: "Act." }
			]
		},
		defaultSort: "activationTime",
		defaultSortType: -1,
		groupFilter: true
	}

	useEffect(() => {
		makeData();
	}, [product]);

	const makeData = () => {
		var category = [];
		if (!product.acquisitions)
			return;
		product.acquisitions.map((x) => {
			if (!category.includes(x.modality))
				category.push(x.modality)
		})
		setChartCategory(category);

		var result = [];
		for (var i = days - 1; i >= 0; i--) {
			var localDate = moment().add(-1 * i, 'days');
			var obj = { date: localDate.format("MM/DD"), odate: localDate.format("YYYY-MM-DD") };
			category.map(m => {
				obj[m] = 0;
			});
			result.push(obj)
		}
		product.acquisitions.map((value) => {
			var localDate = moment.utc(value.activationTime).local().format("YYYY-MM-DD");
			var foundIndex = result.findIndex(x => x.odate == localDate);
			if (result[foundIndex])
				result[foundIndex][value.modality]++;
		});
		setChartData(result);
		setHasChartData(product.acquisitions.filter(x => x.activationTime > moment().add(-1 * days, 'days')).length > 0)
	}

	const handleClick = (item) => {
		setChartFilter({
			date: item.data.odate,
			modality: item.id
		});
		console.log(item);
	}

	const chartOptions = {
		height: height,
		handleClick: handleClick
	}

	const mapList = () => {
		return product.acquisitions;
	}

	return (
		<>
			{product.acquisitions &&
				<>
					{isAdmin &&
						<>
							{product.acquisitions.length > 0 &&
								<>
									{(onlyGraph && hasChartData) && <RayChartBar data={chartData} category={chartCategory} indexBy="date" options={chartOptions} />}
									{!onlyGraph && <div className="section">
										{hasChartData &&
											<div className="card mb-1">
												<RayChartBar data={chartData} category={chartCategory}
													indexBy="date" options={chartOptions} />
											</div>}
										{(showtable && product.acquisitions.length > 0) &&
											<div className="card">
												<div className="card-body">
													<RayTable
														data={mapList()}
														columns={AdminProductACQListHeader}
														options={options} />
												</div>
											</div>
										}
									</div>}
								</>
							}
							{(product.acquisitions.length === 0 && !onlyGraph) &&
								<div className="section">
									<NoRecord title={t('norecords')} contents={t('norecordsfound')} />
								</div>
							}
						</>
					}
					{!isAdmin &&
						<>
							{product.acquisitions.filter(x => x.activationTime > moment().add(-1 * days, 'days')).length > 0 &&
								<>
									{onlyGraph && <RayChartBar data={chartData} category={chartCategory} indexBy="date" options={chartOptions} />}
									{!onlyGraph && <div className="section">
										<div className="card mb-1">
											<RayChartBar data={chartData} category={chartCategory}
												indexBy="date" options={chartOptions} />
										</div>
										{(showtable && product.acquisitions.length > 0) &&
											<div className="card">
												<div className="card-body">
													<RayTable
														data={mapList()}
														columns={AdminProductACQListHeader}
														options={options} />
												</div>
											</div>
										}
									</div>}
								</>
							}
							{(product.acquisitions.filter(x => x.activationTime > moment().add(-1 * days, 'days')).length === 0 && !onlyGraph) &&
								<div className="section">
									<NoRecord title={t('norecords')} contents={t('norecordsfound')} />
								</div>
							}
						</>
					}
				</>
			}
		</>
	);
};

export default ProductInfoACQ;