import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { ServiceAction, AdminUserAction, CommonAction } from '../../actions';
import { UserSimpleCard } from '../../components/common/SimpleLabel';
import { RayDelButton } from '../../components/common/Buttons';
import { ExportExcelClass } from '../common/Exports';
import { useTranslation } from 'react-i18next';
import { Translate as t } from '../../config/Translate';
import Loader from 'react-loader-spinner';

const ServiceInfoComments = ({ service, Comment, user, allUsers, Users }) => {
	const isAdmin = location.pathname.toLowerCase().startsWith('/admin/');
	// const [t] = useTranslation();
	const [comment, setComment] = useState("");
	const [oldcomment, setOldComment] = useState("");
	const [showAddbox, setShowAddbox] = useState(false);
	const [exportData, setExportData] = useState();
	const [sort, setSort] = useState("desc");
	const [list, setList] = useState([]);
	const [isNew, setIsNew] = useState(true);
	const [commentTo, setCommentTo] = useState('');
	const [updateId, setUpdateId] = useState("");
	const [userList, setUserList] = useState([]);
	const [serverTime, setServerTime] = useState('');
	const [request, setRequest] = useState(false);

	useEffect(() => {
		Users();
		setList([...service.comments]);
		if(request) {
			setRequest(false);
		}
	}, [service])

	useEffect(() => {
		setUserList([...allUsers]);
	}, [allUsers]);

	useEffect(async () => {
		exportItems();
		const response = await CommonAction.ServerTime();
		setServerTime(response.data)
	}, [list])

	const handleChangeData = (e) => {
		setComment(e.target.value);
	}

	const AddUpdateComment = () => {
		var commentData = {
			comment: comment,
			commentTo,
		};

		if (!isNew) {
			commentData["commentId"] = updateId;
			commentData["oldval"] = oldcomment;
		}

		setComment("");
		setCommentTo("");
		setShowAddbox(false);
		setRequest(true);
		Comment(service._id, isNew ? "addComment" : "updateComment", commentData);
	}

	const removeComment = (delcom) => {
		const commentData = {
			commentId: delcom._id,
			oldval: delcom.comment
		}
		Comment(service._id, "delComment", commentData);
	}

	const showDateTime = (r) => {
		var rList = [];
		// rList.push(moment.utc(r).local().fromNow());
		rList.push(moment.utc(r).local().from(serverTime));
		rList.push(<small>({moment.utc(r).local().format('YYYY-MM-DD HH:mm:ss')})</small>);
		return rList;
	}

	const nl2br = (text) => {
		var html = [];
		if (text) {
			text.split('\n').map((line, idx) => {
				html.push(<React.Fragment key={idx}>{line}<br /></React.Fragment>);
			})
		} else {
			return "N/A";
		}
		return html;
	}

	const getSortList = () => {
		return sort == "asc" ? list.sort((a, b) => a.createDate - b.createDate) : list.sort((a, b) => b.createDate - a.createDate);
	}

	const exportItems = () => {
		var items = [];
		getSortList().map(c => {
			items.push({
				...c,
				createDate: moment.utc(c.createDate).local().format('YYYY-MM-DD HH:mm:ss')
			});
		});

		setExportData({
			...exportData,
			items: items,
			filename: service.ticketKey + " - Comments",
			sheettitle: "Comments",
			headers: [
				{ label: "Creator", name: "creator" },
				{ label: "Comment", name: "comment", alignment: { wrapText: true } },
				{ label: "Date", name: "createDate" },
			]
		});
	}

	const updateStart = (c) => {
		setIsNew(false);
		setUpdateId(c._id);
		setComment(c.comment);
		setOldComment(c.comment);
		setShowAddbox(true);

		setCommentTo('');
	}

	const newStart = () => {
		setIsNew(true);
		setUpdateId("");
		setComment("");
		setShowAddbox(true);

		setCommentTo('');
	}

	const replyStart = (commentToAdminId) => {
		setIsNew(true);
		setUpdateId("");
		setComment("");
		setShowAddbox(true);

		setCommentTo(commentToAdminId);
	}

	const getUserName = (adminId) => {
		let userName = "";

		userList.forEach(x => {
			if (x.adminid == adminId)
				userName = x.firstName + ' ' + x.lastName;
		});

		return userName;
	}

	return (
		<>
			{commentTo &&
				<div className='p-1 text-primary'>
					@{getUserName(commentTo)}
				</div>
			}

			{!showAddbox &&
				<div className="p-1 bdT1 mb-1">
					<button className="btn btn-primary btn-block" value="" onClick={(e) => newStart()}>
					{request && <Loader
						type='ThreeDots'
						color='#fff'
						width={30}
						height={30}
						visible={true}
						radius={10}
						style={{ display: 'inline-block', marginRight: '10px' }} />
					}
					{!request && t('addcomments')}
					</button>
					{service.comments.length == 0 && <div className="text-muted text-center mt-1 mb-1">{t('nocomments')}</div>}
				</div>
			}
			{showAddbox &&
				<div className="m-b-5 clearfix p-1">
					<textarea name="comment" className="form-control h-140"
						onChange={(e) => handleChangeData(e)}
						value={comment}></textarea>
					<div className="btn-group btn-block">
						<button className="btn btn-dark" value="" onClick={(e) => { setShowAddbox(false); setCommentTo(''); }}>{t('cancel')}</button>
						<button className="btn btn-primary" value="" onClick={(e) => AddUpdateComment()}>{isNew ? t('add') : t('update')}</button>
					</div>
				</div>
			}
			{
				(!(showAddbox && !isNew) && service.comments.length > 0 && !commentTo) &&
				<div>
					<div className="p-1 row border-top">
						<div className="col"></div>
						<div className="col text-right">
							<button className="btn btn-sm btn-primary m-0 me-1"
								onClick={(e) => setSort(sort == "asc" ? "desc" : "asc")}>
								{t('sort')} : {sort == "asc" && <ion-icon name="arrow-down-outline"></ion-icon>}
								{sort == "desc" && <ion-icon name="arrow-up-outline"></ion-icon>}
							</button>
							{isAdmin && <ExportExcelClass data={exportData} />}
						</div>
					</div>
					<div className="border-top">
						<div className="timeline ms-3">
							{getSortList().map((c, idx) =>
								<div key={c._id} className="item">
									<div className="dot bg-primary"></div>
									<div className="content">
										<div className="title">
											<UserSimpleCard user={getUserName(c.creator)} />/ {showDateTime(c.createDate)}
										</div>
										<h4 className="text">
											{
												c.commentTo && <label className='text-primary' style={{ marginRight: '5px' }}>@{getUserName(c.commentTo)}</label>
											}

											{nl2br(c.comment)}
										</h4>

										{user.adminid == c.creator && <div className="btn btn-group m-0 p-0">
											<button className="btn btn-info btn-sm mt-0" onClick={() => updateStart(c)}>Update</button>
											<RayDelButton css="btn btn-danger btn-sm me-2 mt-0" callback={(c) => removeComment(c)} item={c} />
										</div>}
										<div className="btn btn-group m-0 p-0">
											<button className="btn btn-primary btn-sm mt-0 float-right" onClick={() => { replyStart(c.creator) }}>{t('reply')}</button>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			}
		</>
	);
};

const mapState = (state) => {
	const service = state.ServiceReducer.service;
	const user = state.AuthReducer.user;
	const allUsers = state.AdminUserReducer.users;

	return { user, service, allUsers };
}

const mapDispatch = dispatch => ({
	Comment: (_id, type, data) => dispatch(ServiceAction.Comment(_id, type, data)),
	Users: () => dispatch(AdminUserAction.GetUserAll())
});

export default connect(mapState, mapDispatch)(ServiceInfoComments);