import moment from 'moment';
import { store } from '../store';

const AggrProductCountAll = () => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	return {
		type: 'product',
		aggregate:  showmodel[0] !== '' ? [
			{ $match: { 'model': { $in: showmodel } } }, { $count: 'count' }
		]:[
			{ $count: 'count' }
		]
	};
};

const AggrProductCountInstalled = () => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	return {
		type: 'product',
		aggregate: showmodel[0] !== '' ?  [
			{ $match: { 'installedDate': { $gt: 1065767416000 }, 'model': { $in: showmodel } } },
			{ $count: 'count' }
		] : [
			{ $match: { 'installedDate': { $gt: 1065767416000 } } },
			{ $count: 'count' }
		],
	}
}

const AggrProductNewInstalled = (deviceCategory, days) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	return {
		type: 'product',
		aggregate: showmodel[0] !== '' ? [
			{ $match: { 'model': { $in: showmodel }, 'installedDate': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 }, }, },
			{ $count: 'count' },
		] : [
			{ $match: { 'installedDate': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 }, }, },
			{ $count: 'count' },
		]
	};
}

const AggrProductsInstalledLastNDays = (deviceCategory, days) => {
	let aggr = {};
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $lookup: { from: 'sites', localField: 'siteId', foreignField: 'siteId', as: 'site' } },
		{ $project: { _id: 0, sn: 1, model: 1, site: 1, siteId: 1, installedDate: 1 } },
		{ $unwind: '$site' },
		{ $sort: { 'installedDate': -1 } }
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'siteId': { '$ne': "" }, 'installedDate': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 }, 'model': { $in: showmodel } } } : 
		{ $match: { 'siteId': { '$ne': "" }, 'installedDate': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 } } }
	)

	aggr = {
		type: 'product',
		aggregate
	};

	return aggr;
}

const AggrAcqLastNDays = (days) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $project: { acq: '$acquisitions', _id: 0 } },
		{ $unwind: '$acq' },
		{ $count: 'count' },
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'acq.activationTime': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 }, 'model': { $in: showmodel } } } : 
		{ $match: { 'acq.activationTime': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 } } }
	)
	return {
		type: 'product',
		aggregate
	};
};

const AggrErrorLastNDays = (days) => {	
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $project: { sn: 1, err: '$errs', _id: 0 } },
		{ $unwind: '$err' },
		{ $count: 'count' },
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'err.begin': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 }, 'installedDate': { $gt: 1065398400000 }, 'model': { $in: showmodel } } } : 
		{ $match: { 'err.begin': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 }, 'installedDate': { $gt: 1065398400000 } } }
	)
	return {
		type: 'product',
		aggregate
	};
};

const AggrErrorNotCloseCount = (deviceCategory) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $project: { sn: 1, installedDate: 1, err: '$errs', _id: 0 } },
		{ $unwind: '$err' },
		{ $match: { 'err.closer': null, 'err.end': null, 'installedDate': { $gt: 1065398400000 } } },
		{ $count: 'count' }
	];
	if(showmodel[0] !== ''){
		aggregate.unshift({ $match: { 'model': { $in: showmodel } } });
	}
	console.log('AggrErrorNotCloseCount', aggregate);
	return {
		type: 'product',
		aggregate
	};
}

const AggrErrorNotClose = (deviceCategory) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $project: { sn: 1, installedDate: 1, errs: 1, _id: 0 } },
		{ $unwind: '$errs' },
		{ $match: { 'errs.closer': null, 'errs.end': null, 'installedDate': { $gt: 1065398400000 } } },
		{ $group: { _id: { label: '$errs.code' }, value: { $sum: 1 }, }, },
		{ '$sort': { 'value': -1 } },
	];
	if(showmodel[0] !== ''){
		aggregate.unshift({ $match: { 'model': { $in: showmodel } } });
	}
	return {
		type: 'product',
		aggregate
	};
}

const AggrErrorNotCloseLastNDays = (days) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $project: { sn: 1, err: '$errs', _id: 0 }, },
		{ $unwind: '$err' },
		{ $count: 'count' }
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'err.closer': null, 'err.begin': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 },'installedDate': { $gt: 1065398400000 }, 'model': { $in: showmodel } } } : 
		{ $match: { 'err.closer': null, 'err.begin': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 },'installedDate': { $gt: 1065398400000 } } }
	)
	return {
		type: 'product',
		aggregate
	};
};

const AggrErrorNotCloseLastNHours = (hours) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $project: { sn: 1, err: '$errs', _id: 0 }, },
		{ $unwind: '$err' },
		{ $count: 'count' }
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'installedDate': { $gt: 1065398400000 }, 'err.closer': null, 'err.begin': { $gte: moment.utc(moment().utc()).add(-1 * hours, 'hours').unix() * 1000 }, 'model': { $in: showmodel } } } : 
		{ $match: { 'installedDate': { $gt: 1065398400000 }, 'err.closer': null, 'err.begin': { $gte: moment.utc(moment().utc()).add(-1 * hours, 'hours').unix() * 1000 } } }
	)
	return {
		type: 'product',
		aggregate
	};
};

const AggrAcqChartLastNDays = (group, days) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $unwind: '$acquisitions' },
		{ GEN: "PROJECT-DAILY-GROUP-COUNT:" + 	//Daily로 Group
			'$acquisitions.' + group + ":" + 	//name : Group Key
			'$acquisitions.activationTime'		//date : Group Key
		},
		{ $group: { _id: { date: '$date', val: '$name' }, count: { $sum: 1 }} }
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'acquisitions.activationTime': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 }, 'model': { $in: showmodel } } } : 
		{ $match: { 'acquisitions.activationTime': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 } } }
	)
	return {
		type: 'product',
		aggregate
	};
};

const AggrModelPercent = (group, days) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $group: { _id: { label: '$model' }, value: { $sum: 1 } } },
		{ $sort: { 'value': -1 } }
	];
	if(showmodel[0] !== ''){
		aggregate.unshift({ $match: { 'model': { $in: showmodel } } });
	}
	return {
		type: 'product',
		aggregate
	};
};

const AggrModelPercentByNDays = (deviceCategory, days) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $group: { _id: { label: '$model' }, value: { $sum: 1 } } },
		{ $sort: { 'value': -1 } }
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'installedDate': { $gte: days ? moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 : 1065767416000 }, 'model': { $in: showmodel } } } : 
		{ $match: { 'installedDate': { $gte: days ? moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 : 1065767416000 } } }
	)
	return {
		type: 'product',
		aggregate
	};
}

const AggrErrorPercentLastNDays = (days) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $unwind: '$errs' },
		{ '$project': { 'errs.code': 1 } },
		{ '$addFields': { 'code': '$errs.code' } },
		{ $group: { _id: { label: '$code' }, value: { $sum: 1 } } },
		{ $sort: { 'value': -1 } }
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'errs.begin': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 }, 'installedDate': { $gt: 1065398400000 }, 'model': { $in: showmodel } } } : 
		{ $match: { 'errs.begin': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 }, 'installedDate': { $gt: 1065398400000 } } }
	)
	return {
		type: 'product',
		aggregate
	};
};

const AggrErrorChartLastNDays = (group, days) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	let aggregate = [];
	if(showmodel[0] !== ''){
		aggregate = [
			{ $unwind: '$errs' },
			{ $match: { 'errs.begin': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 }, 'installedDate': { $gt: 1065398400000 }, 'model': { $in: showmodel } } },
			{ GEN: "PROJECT-DAILY-GROUP-COUNT:" + 	//Daily로 Group
				'$errs.' + group + ":" + 			//name : Group Key
				'$errs.begin'						//date : Group Key
			},
			{ $group: { _id: { date: '$date', val: '$name' }, count: { $sum: 1 } } }
		];
	}else{
		aggregate = [
			{ $unwind: '$errs' },
			{ $match: { 'errs.begin': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 }, 'installedDate': { $gt: 1065398400000 } } },
			{ GEN: "PROJECT-DAILY-GROUP-COUNT:" + 	//Daily로 Group
				'$errs.' + group + ":" + 			//name : Group Key
				'$errs.begin'						//date : Group Key
			},
			{ $group: { _id: { date: '$date', val: '$name' }, count: { $sum: 1 } } }
		];
	}
	return {
		type: 'product',
		aggregate
	};
};

const AggrErrorOpenLastest = () => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	let aggregate = [ ];
	if(showmodel[0] !== ''){
		aggregate = [
			{ $lookup: { from: 'sites', localField: 'siteId', foreignField: 'siteId', as: 'sites' }},
			{ $addFields: { 'site': { "$arrayElemAt": ["$sites", 0] } } },
			{ $project: { err: '$errs', sn: 1, site: 1, siteId: 1, installedDate: 1, _id: 0 } },
			{ $unwind: '$err' },
			{ $match: { 'err.closer': null, 'err.end': null, 'err.begin': { $gte: moment.utc(moment().utc()).add(-30, 'days').unix() * 1000 }, 'installedDate': { $gt: 1065398400000 }, 'model': { $in: showmodel } } }
		];
	}else{
		aggregate = [
			{ $lookup: { from: 'sites', localField: 'siteId', foreignField: 'siteId', as: 'sites' }},
			{ $addFields: { 'site': { "$arrayElemAt": ["$sites", 0] } } },
			{ $project: { err: '$errs', sn: 1, site: 1, siteId: 1, installedDate: 1, _id: 0 } },
			{ $unwind: '$err' },
			{ $match: { 'err.closer': null, 'err.end': null, 'err.begin': { $gte: moment.utc(moment().utc()).add(-30, 'days').unix() * 1000 }, 'installedDate': { $gt: 1065398400000 } } }
		];
	}
	return {
		type: 'product',
		aggregate
	};
}

const AggrLatestAcqProduct = (limit) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $unwind: '$acquisitions' },
		{ $sort: { 'acquisitions.activationTime': -1 } },
		{ $group: { _id: { x: '$sn', doa: '$doa', dim: '$dim', y: '$model', k: '$_id' }, count: { $sum: 1 }  } },
		{ $limit: limit },
		{ $addFields: { title: '$_id.x', subtitle: '$_id.y', id: '$_id.k' } },
	];
	if(showmodel[0] !== ''){
		aggregate.unshift({ $match: { 'model': { $in: showmodel } } });
	}
	return {
		type: 'product',
		aggregate
	};
};

const AggrLatestOpenErrors = (days) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ '$unwind': '$errs' },
		{ $match: { 'errs.begin': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 } } }
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'errs.closer': null, 'installedDate': { $gt: 1065398400000 }, 'model': { $in: showmodel } } } : 
		{ $match: { 'errs.closer': null, 'installedDate': { $gt: 1065398400000 } } }
	)
	return {
		type: 'product',
		aggregate
	};
};
 
const AggrServiceResolve3Step = () => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $project: { 'resolvetime': { '$subtract': ['$closedDate', '$createDate'] } } },
		{ $facet:
			{
				'a': [ { '$match': { 'resolvetime': { '$lt': 8640000 } } }, { '$count': 'count' } ],
				'b': [ { '$match': { 'resolvetime': { '$gt': 8640000, '$lt': 25920000 } } }, { '$count': 'count' } ],
				'c': [ { '$match': { 'resolvetime': { '$gt': 25920000, '$lt': 60480000 } } }, { '$count': 'count' } ],
				'd': [ { '$match': { 'resolvetime': { '$gt': 60480000 } } }, { '$count': 'count' } ]
			}
		}
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'status': 'Close', 'model': { $in: showmodel } } } : 
		{ $match: { 'status': 'Close' } }
	)
	return {
		type: 'service',
		aggregate
	};
};

const AggrDIMDOACountPrd = () => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ 
			$facet: {
				"dim": [ { "$match": { dim: true } }, { "$count": "count" } ],
				"doa": [ { "$match": { doa: true } }, { "$count": "count" } ],
			}
		},
		{ $addFields: { "dim": { "$arrayElemAt": ["$dim", 0] }, "doa": { "$arrayElemAt": ["$doa", 0] } } }
	];
	if(showmodel[0] !== ''){
		aggregate.unshift({ $match: { 'model': { $in: showmodel } } });
	}
	return {
		type: 'product',
		aggregate
	};
};

const AggrLatestInstalledPrd = (days) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $count: 'count' }
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'installedDate': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 }, 'model': { $in: showmodel } } } : 
		{ $match: { 'installedDate': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 } } }
	)
	return {
		type: 'product',
		aggregate
	};
};

const AggrLongUsePrd = (year, count) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{
			$project: {
				'isConnected': 1,
				'installedDate': 1,
				'sitename': 1,
				'companyId': 1,
				'country': 1,
				'siteId': 1,
				'sitename': 1,
				'model': 1,
				'option': 1,
				'sn': 1,
				'dim': 1,
				'doa': 1,
				'waranties': 1
			}
		},
		{ $unwind: '$waranties' },
		{ $match: { 'waranties.dest': "warproduct_product", 'waranties.category': "wartype_ms" } },
		{ $lookup: { from: 'companies', localField: 'companyId', foreignField: 'companyId', as: 'companyInfo' } },
		{ $addFields: { companyInfo: { '$arrayElemAt': ['$companyInfo', 0] } } },
		{ $limit: parseInt(count) },
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'installedDate': { $lte: moment.utc(moment().utc()).add(-1 * year, 'years').unix() * 1000 },'siteId': { $ne: "" }, 'model': { $in: showmodel } } } : 
		{ $match: { 'installedDate': { $lte: moment.utc(moment().utc()).add(-1 * year, 'years').unix() * 1000 },'siteId': { $ne: "" } } }
	)
	return {
		type: 'product',
		aggregate
	};
};

const AggrInventoryProductCount = (deviceCategory) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $count: 'count' }
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { installedDate: { $lte: 1065398400000 }, 'model': { $in: showmodel } } } : 
		{ $match: { installedDate: { $lte: 1065398400000 } } }
	)
	return {
		type: 'product',
		aggregate
	};
};

const AggrInventoryProduct = (deviceCategory) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $lookup: { from: 'sites', localField: 'siteId', foreignField: 'siteId', as: 'sites' } },
		{ $group: { _id: { label: '$model' }, value: { $sum: 1 }, }, },
		{ $addFields: { 'model': '$_id.label' } },
		{ '$sort': { 'value': -1, } }
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { installedDate: { $lte: 1065398400000 }, 'model': { $in: showmodel } } } : 
		{ $match: { installedDate: { $lte: 1065398400000 } } }
	)
	return {
		type: 'product',
		aggregate
	};
}

const AggrInventoryProductByCountry = (deviceCategory) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $lookup: { from: 'companies', localField: 'companyId', foreignField: 'companyId', as: 'companies' } },
		{ $group: { _id: { label: '$companies.country' }, value: { $sum: 1 } } },
		{ $addFields: { 'country': '$_id.label' } },
		{ '$sort': { 'value': -1 } }
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { installedDate: { $lte: 1065398400000 }, 'model': { $in: showmodel } } } : 
		{ $match: { installedDate: { $lte: 1065398400000 } } }
	)
	return {
		type: 'product',
		aggregate
	};
}

const AggrEMSNewOld = () => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $facet: {
				"new": [
					{ "$match": { emsver: { $gt: '3.0.0.0' } } },
					{ "$count": "count" },
				],
				"old": [
					{ '$match': { "$or": [{ emsver: { $lt: '3.0.0.0' } }, { emsver: null }] } },
					{ "$count": "count" },
				],
			}
		},
		{ $addFields: {"new": { "$arrayElemAt": ["$new", 0] }, "old": { "$arrayElemAt": ["$old", 0] } } }
	];
	if(showmodel[0] !== ''){
		aggregate.unshift({ $match: { 'model': { $in: showmodel } } });
	}
	return {
		type: 'product',
		aggregate
	};
};

const AggrLatestInstalled = (count) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $lookup: { from: 'sites', localField: 'siteId', foreignField: 'siteId', as: 'sites' }},
		{ $addFields: { 'site': { "$arrayElemAt": ["$sites", 0] } } },
		{ $sort: { 'installedDate': -1 } },
		{ $limit: parseInt(count) },
		{ $project: { 'isConnected': 1, 'installedDate': '$installedDate', 'sitename': '$site.sitename', 'model': '$model', 'sn': '$sn', 'country': '$site.country','dim': '$dim','doa': '$doa'} },
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'siteId': { '$ne': "" }, 'model': { $in: showmodel } } } : 
		{ $match: { 'siteId': { '$ne': "" } } }
	)
	return {
		type: 'product',
		aggregate
	};
};

const AggrErrorCounts = (days, count) => {
	const daytime = moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{
			'$project': {
				'isConnected': 1,
				'sn': 1,
				'dim': 1,
				'doa': 1,
				'allerrs': {
					'$size': {
						'$filter': {
							'input': '$errs',
							'as': 'errs',
							'cond': { '$gte': ['$$errs.begin', daytime] }
						}
					}
				},
				'errs': {
					'$size': {
						'$filter': {
							'input': '$errs',
							'as': 'errs',
							'cond': {
								'$and': [
									{ '$gte': ['$$errs.begin', daytime] },
									{ '$ne': ['$$errs.end', null] }]
							}
						}
					}
				}
			}
		},
		{
			'$addFields': { 'notclosed': { '$subtract': ['$allerrs', '$errs'] } }
		},
		{
			'$sort': {
				'notclosed': -1,
				'allerrs': -1
			}
		},
		{ $limit: parseInt(count) },
	];
	if(showmodel[0] !== ''){
		aggregate.unshift({ $match: { 'model': { $in: showmodel } } } )
	}
	return {
		type: 'product',
		aggregate
	};
};

const AggrModelPercent2 = (deviceCategory) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $group: { _id: { label: '$model' }, value: { $sum: 1 } } },
		{ $addFields: { 'model': '$_id.label' } },
		{ '$sort': { 'value': -1 } }
	];
	if(showmodel[0] !== ''){
		aggregate.unshift({ $match: { 'model': { $in: showmodel } } } )
	}
	return {
		type: 'product',
		aggregate
	};
};

const AggrProductService = (count) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ '$lookup': { 'from': 'services', 'localField': 'sn', 'foreignField': 'sn', 'as': 'services' } },
		{
			'$project': {
				'isConnected': 1,
				'sn': 1,
				'doa': 1,
				'dim': 1,
				'all': { '$size': '$services' },
				'open': {
					'$size': {
						'$filter': {
							'input': '$services',
							'as': 'service',
							'cond': {
								'$ne': ['$$service.status', 'Close']
							}
						}
					}
				}
			}
		},
		{ '$addFields': { 'close': { '$subtract': ['$all', '$open'] } } },
		{ '$sort': { 'open': -1, 'all': -1 } },
		{ $limit: parseInt(count) },
	];
	if(showmodel[0] !== ''){
		aggregate.unshift({ $match: { 'model': { $in: showmodel } } } )
	}
	return {
		type: 'product',
		aggregate
	};
};

const AggrLatestAcqProduct2 = (days, limit) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $unwind: '$acquisitions' },
		{ $match: { 'acquisitions.activationTime': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 } }},
		{ $group: {
				_id: {
					isConnected: '$isConnected',
					x: '$sn',
					dim: '$dim',
					doa: '$doa',
					y: '$model',
					k: '$_id',
				},
				count: { $sum: 1 },
			},
		},
		{ '$sort': { 'count': -1 } },
		{ $limit: parseInt(limit) },
		{ $addFields: { sn: '$_id.x', isConnected: '$_id.isConnected', subtitle: '$_id.y', id: '$_id.k' }},
	];
	if(showmodel[0] !== ''){
		aggregate.unshift({ $match: { 'model': { $in: showmodel } } } )
	}
	return {
		type: 'product',
		aggregate
	};
};

const AggrTotalProductByCountry = (deviceCategory) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $lookup: { from: 'companies', localField: 'companyId', foreignField: 'companyId', as: 'companies' } },
		{ $group: { _id: { label: '$companies.country' }, value: { $sum: 1 }, }, },
		{ $addFields: { 'country': '$_id.label' } },
		{ '$sort': { 'value': -1, } }
	];
	if(showmodel[0] !== ''){
		aggregate.unshift({ $match: { 'model': { $in: showmodel } } } )
	}
	return {
		type: 'product',
		aggregate
	};
}

const AggrNewInstalledByCountry = (days) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $lookup: { from: 'companies', localField: 'companyId', foreignField: 'companyId', as: 'companies' } },
		{ $group: { _id: { label: '$companies.country' }, value: { $sum: 1 } } },
		{ $addFields: { 'country': '$_id.label' } },
		{ '$sort': { 'value': -1 } }
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'siteId': { '$ne': "" }, 'installedDate': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 }, 'model': { $in: showmodel } } } : 
		{ $match: { 'siteId': { '$ne': "" }, 'installedDate': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 } } }
	)
	return {
		type: 'product',
		aggregate
	};
}

const AggrProductCountry = (deviceCategory) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $lookup: { from: 'sites', localField: 'siteId', foreignField: 'siteId', as: 'sites' } },
		{ $addFields: { 'site': { "$arrayElemAt": ["$sites", 0] } } },
		{ $group: { _id: { label: '$site.country' }, value: { $sum: 1 } } },
		{ $addFields: { 'country': '$_id.label' } },
		{ '$sort': { 'value': -1 } },
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'siteId': { '$ne': "" }, 'model': { $in: showmodel } } } : 
		{ $match: { 'siteId': { '$ne': "" } } }
	)
	return {
		type: 'product',
		aggregate
	};
};

const AggrProductInstallationsByCountry = (deviceCategory) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $match: { 'installedDate': { $gt: 1065398400000 } } },
		{ $lookup: { from: 'companies', localField: 'companyId', foreignField: 'companyId', as: 'companies' } },
		{ $group: { _id: { label: '$companies.country' }, value: { $sum: 1 }, }, },
		{ $addFields: { 'country': '$_id.label' } },
		{ '$sort': { 'value': -1, } }
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'installedDate': { $gt: 1065398400000 }, 'model': { $in: showmodel } } } : 
		{ $match: { 'installedDate': { $gt: 1065398400000 } } }
	)
	return {
		type: 'product',
		aggregate
	};
}

const AggrProductInstallationByCountryLastNdays = (deviceCategory, days) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $lookup: { from: 'companies', localField: 'companyId', foreignField: 'companyId', as: 'companies' } },
		{ $group: { _id: { label: '$companies.country' }, value: { $sum: 1 } } },
		{ $addFields: { 'country': '$_id.label' } },
		{ '$sort': { 'value': -1 } }
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'installedDate': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 }, 'model': { $in: showmodel } } } : 
		{ $match: { 'installedDate': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 } } }
	)
	return {
		type: 'product',
		aggregate
	};
}

const AggrServiceTotalTicketCount = (days) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	let aggregate = [
		(showmodel[0] !== '' ? { $match: { 'isDel': false, 'model': { $in: showmodel } } } : { $match: { 'isDel': false } }),
		{ $count: 'count' },
	];

	if (days > 0) {
		aggregate[0] = {
			$match: {
				...aggregate[0].$match,
				'createDate': {
					$gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000
				}
			}
		}
	}

	return {
		type: 'service',
		aggregate
	}
}

const AggrServiceCountByStatus = (deviceCategory) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	let aggregate = [];
	if(showmodel[0] !== ''){
		aggregate = [
			{ $match: { 'status': { $ne: 'Close' }, 'model': { $in: showmodel } } },
			{ $group: { _id: '$status', value: { $sum: 1 } } },
			{ $sort: { value: -1 } }
		]
	}else{
		aggregate = [				
			{ $match: { 'status': { $ne: 'Close' } } },
			{ $group: { _id: '$status', value: { $sum: 1 } } },
			{ $sort: { value: -1 } }
		]
	}
	return {
		type: 'service',
		aggregate
	};
}

const AggrServiceReplacementTracking = (months) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggr = [
		{ $lookup: { from: 'sites', localField: 'siteId', foreignField: 'siteId', as: 'sites' } },
		{ $addFields: { 'site': { "$arrayElemAt": ["$sites", 0] } } },
		{ $project: {
			'replacements': 1,
			'sn': 1,
			'ticketKey': 1,
			'ticketNationCode': 1,
			'companyId': 1,
			'summary': 1,
			'createDate': 1,
			'status': 1,
			'siteId': 1,
			'site': 1
		}},
		{ $unwind: '$replacements' },
		{ $sort: { 'replacements.sendDate': -1 } }
	]
	let aggregate = [];
	if(showmodel[0] !== ''){
		aggregate = [
			{ $match: { 'replacements.sendDate': { $gte: moment.utc(moment().utc()).add(-1 * months, 'months').unix() * 1000 }, 'model': { $in: showmodel } } },
			...aggr
		]
	}else{
		aggregate = [				
			{ $match: { 'replacements.sendDate': { $gte: moment.utc(moment().utc()).add(-1 * months, 'months').unix() * 1000 } } },
			...aggr
		]
	}
	return {
		type: 'service',
		aggregate
	};
};

const AggrServiceReplacementPortion = (months) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggr = [
		{ $project: { 'replacements': 1 } },
		{ $unwind: '$replacements' },
		{ $group: { _id: { label: '$replacements.subtitle' }, value: { $sum: 1 } } },
		{ $addFields: { title: '$_id.label' } },
		{ $sort: { value: -1 } }
	]
	let aggregate = [];
	if(showmodel[0] !== ''){
		aggregate = [
			{ $match: { 'createDate': { $gte: moment.utc(moment().utc()).add(-1 * months, 'month').unix() * 1000 }, 'model': { $in: showmodel } } },
			...aggr
		]
	}else{
		aggregate = [				
			{ $match: { 'createDate': { $gte: moment.utc(moment().utc()).add(-1 * months, 'month').unix() * 1000 } } },
			...aggr
		]
	}
	return {
		type: 'service',
		aggregate
	};
};

const ServiceTotalEscalatedCount = () => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	let aggregate = [];
	if(showmodel[0] !== ''){
		aggregate = [
			{ $match: { 'model': { $in: showmodel } } },
			{ $match: { 'escalated': true } },
			{ $count: 'count' }
		]
	}else{
		aggregate = [				
			{ $match: { 'escalated': true } },
			{ $count: 'count' }
		]
	}
	return {
		type: 'service',
		aggregate
	};
}

const AggrServiceLastestOpenTickets = (days) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggr = [
		{ $lookup: { from: 'sites', localField: 'siteId', foreignField: 'siteId', as: 'sites' } },
		{ $addFields: { 'site': { "$arrayElemAt": ["$sites", 0] } } },
		{ $sort: { createDate: -1 } }
	]
	let aggregate = [];
	if(showmodel[0] !== ''){
		aggregate = [
			{ $match: { 'createDate': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 }, 'status': { $ne: 'Close' }, 'statusType': { $ne: 'Close' }, 'isDel': { $eq: false }, 'model': { $in: showmodel } } },
			...aggr
		]
	}else{
		aggregate = [
			{ $match: { 'createDate': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 }, 'status': { $ne: 'Close' }, 'statusType': { $ne: 'Close' }, 'isDel': { $eq: false } } },
			...aggr
		]
	}
	return {
		type: 'service',
		aggregate
	};
}

const AggrServiceLastestClosedTickets = (days) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	let aggr = [
		{ $match: {	'status': { $eq: 'Close' }, 'isDel': { $eq: false } } },
		{ $lookup: { from: 'sites', localField: 'siteId', foreignField: 'siteId', as: 'sites' } },
		{ $addFields: { 'site': { "$arrayElemAt": ["$sites", 0] } } },
		{ $sort: { closedDate: -1 } }
	]
	if (days > 0) {
		aggr[0] = { $match: { ...aggr[0].$match, 'createDate': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 } } }
	}
	let aggregate = [];
	if(showmodel[0] !== ''){
		aggregate = [
			{ $match: { 'model': { $in: showmodel } } },
			...aggr
		]
	}else{
		aggregate = [
			...aggr
		]
	}
	return {
		type: 'service',
		aggregate
	};
}

const AggrServiceOpenCount = (deviceCategory) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $count: 'count' }
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'status': { $ne: 'Close' }, 'model': { $in: showmodel } } } : 
		{ $match: { 'status': { $ne: 'Close' } } }
	)
	return {
		type: 'service',
		aggregate
	};
}

const AggrServiceTotalClosedCount = (days) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $count: 'count' },
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'status': { $eq: "Close" }, 'isDel': { $eq: false }, 'model': { $in: showmodel } } } : 
		{ $match: { 'status': { $eq: "Close" }, 'isDel': { $eq: false } } }
	)
	if (days > 0) {
		aggregate[0] = {
			$match: {
				...aggregate[0].$match,
				'createDate': {
					$gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000
				}
			}
		}
	}
	return {
		type: 'service',
		aggregate
	};
}

const AggrServiceOverdueOnlyCount = (days) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $count: 'count' }
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'createDate': { $gte: moment.utc(moment().utc()).add(1 * days, 'days').unix() * 1000 }, 'status': { $ne: "Close" }, 'model': { $in: showmodel } } } : 
		{ $match: { 'createDate': { $gte: moment.utc(moment().utc()).add(1 * days, 'days').unix() * 1000 }, 'status': { $ne: "Close" } } }
	)
	return {
		type: 'service',
		aggregate
	};
}

const AggrServiceChartLastMonth = (group, months) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ GEN: "PROJECT-MONTHLY-COUNT:" + 	//Daily로 Group
			'$' + group + ":" + 	//name : Group Key
			'$createDate'		//date : Group Key
		},
		{ $group: { _id: { date: '$date', val: '$name' }, count: { $sum: 1 } } },
		{ $match: { '_id.val': { $ne: '' } } }
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'createDate': { $gt: moment.utc(moment().utc()).add(-1 * months, 'months').unix() * 1000 }, 'model': { $in: showmodel } } } : 
		{ $match: { 'createDate': { $gt: moment.utc(moment().utc()).add(-1 * months, 'months').unix() * 1000 } } }
	)
	return {
		type: 'service',
		aggregate
	};
};

const AggrErrorTotalCount = (weeks) => {
	
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $unwind: '$errs' },
		{ $match: { 'errs.begin': { $gte: moment.utc(moment().utc()).add(-1 * weeks, 'weeks').unix() * 1000 }, 'errs.note': { $ne: 'RAYGuard_AutoErrorClosed' } } },
		{ '$project': { 'errs.code': 1 } },
		{ $group: { _id: { label: '$code' }, count: { $sum: 1 } } }
	];
	if(showmodel[0] !== ''){
		aggregate.unshift( { $match: { 'model': { $in: showmodel } } } )	
	}
	return {
		type: 'product',
		aggregate
	};
}

const AggrErrorOpenCount = () =>  {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $project: { err: '$errs', sn: 1, installedDate: 1 } },
		{ $unwind: '$err' },
		{ $match: { 'err.closer': null, 'err.end': null, 'installedDate': { $gt: 1065398400000 } }  },
		{ $count: 'count' }
	];
	if(showmodel[0] !== ''){
		aggregate.unshift( { $match: { 'model': { $in: showmodel } } } )	
	}
	return {
		type: 'product',
		aggregate
	};
}

const AggrErrorClosedLastest = (days) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $lookup: { from: 'sites', localField: 'siteId', foreignField: 'siteId', as: 'sites' } },
		{ $addFields: { 'site': { "$arrayElemAt": ["$sites", 0] } } },
		{ $project: { err: '$errs', sn: 1, site: 1, siteId: 1 } },
		{ $unwind: '$err' },
		{ $match: { 'err.end': { $gte: moment.utc(moment().utc()).add(-7 * days, 'days').unix() * 1000, $lt: moment.utc(moment().utc()).unix() * 1000 } } },
		{ $sort: { 'err.end': -1 } }
	];
	if(showmodel[0] !== ''){
		aggregate.unshift( { $match: { 'model': { $in: showmodel } } } )	
	}
	return {
		type: 'product',
		aggregate
	};
}

const AggrErrorClosedCount = (days) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $project: { err: '$errs', sn: 1 } },
		{ $unwind: '$err' },
		{ $match: {	'err.end': { $gte: moment.utc(moment().utc()).add(-7 * days, 'days').unix() * 1000, $lt: moment.utc(moment().utc()).unix() * 1000 } } },
		{ $count: 'count' }
	];
	if(showmodel[0] !== ''){
		aggregate.unshift( { $match: { 'model': { $in: showmodel } } } )	
	}
	return {
		type: 'product',
		aggregate
	};
}

const AggrErrorCodeLastNDays = (errorCode, group, days, limit = 100) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $project: getProjectsErr(group) },
		{ $unwind: '$err' },
		{ $match: { 'err.code': errorCode, 'err.begin': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 } } },
		{ $group: { _id: '$' + group + '', value: { $sum: 1 } } },
		{ $sort: { value: -1 } },
		{ $limit: limit }
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'errs.code': errorCode,	'installedDate': { $gt: 1065398400000 }, 'model': { $in: showmodel } } } : 
		{ $match: { 'errs.code': errorCode,	'installedDate': { $gt: 1065398400000 } } }
	)
	return {
		type: 'product',
		aggregate
	};
};

const AggrErrorByCodeLastNDays = (errorCode, group, days) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $unwind: '$errs' },
		{ $match: { 'errs.code': errorCode, 'errs.begin': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 } } },
		{ $addFields: { isClose: { $cond: [{ $gt: ['$errs.end', 0] },'Closed', "Open"] } } },
		{ GEN: "PROJECT-DAILY-GROUP-COUNT:" + 	//Daily로 Group
			'$isClose:' + 						//name : Group Key
			'$errs.begin'						//date : Group Key
		},
		{ $group: { _id: { date: '$date', val: '$name' }, count: { $sum: 1 } } }
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'errs.code': errorCode, 'installedDate': { $gt: 1065398400000 }, 'model': { $in: showmodel } } } : 
		{ $match: { 'errs.code': errorCode, 'installedDate': { $gt: 1065398400000 } } }
	)
	return {
		type: 'product',
		aggregate
	};
};

const AggrAllErrCount = (errorCode) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $unwind: '$errs' },
		{ $match: { 'errs.code': errorCode } },
		{ $addFields: { isClose: { $cond: [{ $gt: ['$errs.end', 0] }, 'Closed', "Open"] } } },
		{ $group: { _id: null, value: { $sum: 1 }, notCloseCount: { $sum: { $cond: [ { $eq: ['$isClose', 'Closed'] }, 0, 1 ] } } } }
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'errs.code': errorCode, 'installedDate': { $gt: 1065398400000 }, 'model': { $in: showmodel } } } : 
		{ $match: { 'errs.code': errorCode, 'installedDate': { $gt: 1065398400000 } } }
	)
	return {
		type: 'product',
		aggregate
	};
};

const AggrBeginEndDiff = (errorCode) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $unwind: '$errs' },
		{ $match: { 'errs.code': errorCode, "errs.end": { '$gt': 0 } } },
		{ $addFields: { "period": { '$subtract': ['$errs.end', '$errs.begin'] } } },
		{ $group: { _id: null, allcount: { '$sum': 1 }, periodsum: { $sum: '$period' } } }
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'errs.code': errorCode, 'installedDate': { $gt: 1065398400000 }, 'model': { $in: showmodel } } } : 
		{ $match: { 'errs.code': errorCode, 'installedDate': { $gt: 1065398400000 } } }
	)
	return {
		type: 'product',
		aggregate
	};
};

const AggrLatestErrorProduct2 = (days, limit) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $unwind: '$errs' },
		{ $match: { 'errs.begin': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 } } },
		{ $group: { _id: { isConnected: '$isConnected', x: '$sn', dim: '$dim', doa: '$doa', y: '$model', k: '$_id', siteId: '$siteId' }, count: { $sum: 1 } } },
		{ $limit: limit },
		{ $addFields: { sn: '$_id.x', isConnected: '$_id.isConnected', subtitle: '$_id.y', id: '$_id.k', siteId: '$_id.siteId' } },
		{ $lookup: { from: 'sites', localField: 'siteId', foreignField: 'siteId', as: 'sites' } },
		{ $addFields: { 'site': { "$arrayElemAt": ["$sites", 0] } } },
		{ '$sort': { 'count': -1 } },
	];
	if(showmodel[0] !== ''){
		aggregate.unshift( { $match: { 'model': { $in: showmodel } } } )	
	}
	return {
		type: 'product',
		aggregate
	}
};

const AggrLatestOpenErrorProduct2 = (days, limit) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $unwind: '$errs' },
		{ $match: { 'errs.begin': { $gte: moment.utc(moment().utc()).add(-1 * days, 'days').unix() * 1000 }, 'status': { $ne: "Close" } } },
		{ $addFields: { isClose: { $cond: [{ $eq: ['$errs.closer', null] }, 'Open', "Closed"] } } },
		{ $group: { _id: { label: '$errs.code' }, value: { $sum: 1 }, notCloseCount: { $sum: { $cond: [ { $eq: ['$isClose', 'Closed'] }, 0, 1 ] } } } },
		{ '$sort': { 'value': -1 } },
		{ $limit: limit }
	];
	if(showmodel[0] !== ''){
		aggregate.unshift( { $match: { 'model': { $in: showmodel } } } )	
	}
	return {
		type: 'product',
		aggregate
	}
};


const AggrErrorPerModel = (deviceCategory) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $unwind: '$errs' },
		{ $match: { 'errs.closer': null, 'errs.end': null, 'installedDate': { $gt: 1065398400000 } } },
		{ $group: { _id: { x: '$model', }, count: { $sum: 1 } } },
		{ '$sort': { 'count': -1 } },
		{ $addFields: { model: '$_id.x', id: '$_id.k' } },
	];
	if(showmodel[0] !== ''){
		aggregate.unshift( { $match: { 'model': { $in: showmodel } } } )	
	}
	return {
		type: 'product',
		aggregate
	};
};

const AggrLatestInstalledSite = (weeks) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ $lookup: { from: 'sites', localField: 'siteId', foreignField: 'siteId', as: 'sites' } },
		{ $addFields: { 'site': { "$arrayElemAt": ["$sites", 0] } } },
		{ $sort: { 'installedDate': -1 } },
		{ $project: {
				'isConnected': 1,
				'installedDate': '$installedDate',
				'sitename': '$site.sitename',
				'model': '$model',
				'sn': '$sn',
				'siteId': '$site.siteId',
				'country': '$site.country',
				'dim': '$dim',
				'doa': '$doa',
			}
		}
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'siteId': { '$ne': "" }, 'installedDate': { $gte: moment.utc(moment().utc()).add(-7 * weeks, 'days').unix() * 1000 }, 'model': { $in: showmodel } } } : 
		{ $match: { 'siteId': { '$ne': "" }, 'installedDate': { $gte: moment.utc(moment().utc()).add(-7 * weeks, 'days').unix() * 1000 } } }
	)
	return {
		type: 'product',
		aggregate
	};
};

const AggrErrorCountsSite = (days) => {
	const daytime = moment.utc(moment().utc()).add(-7 * days, 'days').unix() * 1000
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ '$project': {
				'isConnected': 1, 'siteId': 1, 'sn': 1, 'dim': 1, 'doa': 1,
				'allerrs': { '$size': { '$filter': { 'input': '$errs', 'as': 'errs', 'cond': { '$gte': ['$$errs.begin', daytime] } } } },
				'errs': {
					'$size': {
						'$filter': {
							'input': '$errs',
							'as': 'errs',
							'cond': { '$and': [ { '$gte': ['$$errs.begin', daytime] }, { '$ne': ['$$errs.end', null] }] }
						}
					}
				}
			}
		},
		{ $match: { 'allerrs': { '$gt': 0 } } },
		{ '$lookup': { 'from': 'sites', 'localField': 'siteId', 'foreignField': 'siteId', 'as': 'site' } },
		{ '$addFields': { 'site': { "$arrayElemAt": ["$site", 0] }, 'notclosed': { '$subtract': ['$allerrs', '$errs'] } } },
		{ '$sort': { 'notclosed': -1, 'allerrs': -1 } },
		{ $limit: 10 },
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'siteId': { '$ne': "" }, 'model': { $in: showmodel } } } : 
		{ $match: { 'siteId': { '$ne': "" } } }
	)
	return {
		type: 'product',
		aggregate
	};
};

const AggrProductServiceSite = (count) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ '$lookup': { 'from': 'services', 'localField': 'sn', 'foreignField': 'sn', 'as': 'services' } },
		{ '$project': {
				'isConnected': 1, 'sn': 1, 'siteId': 1, 'doa': 1, 'dim': 1, 'all': { '$size': '$services' },
				'open': {
					'$size': {
						'$filter': {
							'input': '$services',
							'as': 'service',
							'cond': { '$ne': ['$$service.status', 'Close'] }
						}
					}
				}
			}
		},
		{ '$lookup': { 'from': 'sites', 'localField': 'siteId', 'foreignField': 'siteId', 'as': 'site' } },
		{ '$addFields': { 'site': { "$arrayElemAt": ["$site", 0] }, 'close': { '$subtract': ['$all', '$open'] } } },
		{ '$sort': { 'open': -1, 'all': -1 } },
		{ $limit: count },
	];
	aggregate.unshift(showmodel[0] !== '' ? 
		{ $match: { 'siteId': { '$ne': "" }, 'model': { $in: showmodel } } } : 
		{ $match: { 'siteId': { '$ne': "" } } }
	)
	return {
		type: 'product',
		aggregate
	};
};

const AggrLatestAcqProductSite2 = (weeks) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	const aggregate = [
		{ '$lookup': { 'from': 'sites', 'localField': 'siteId', 'foreignField': 'siteId', 'as': 'site' } },
		{ '$addFields': { 'site': { "$arrayElemAt": ["$site", 0] } } },
		{ $unwind: '$acquisitions', },
		{ $match: { 'siteId': { '$ne': "" }, 'acquisitions.activationTime': { $gte: moment.utc(moment().utc()).add(-7 * weeks, 'days').unix() * 1000 } } },
		{
			$group: {
				_id: {
					isConnected: '$isConnected',
					x: '$sn',
					siteId: '$site.siteId',
					sitename: '$site.sitename',
					country: '$site.country',
					dim: '$dim',
					doa: '$doa',
					y: '$model',
					k: '$_id',
				},
				count: { $sum: 1 },
			},
		},
		{ '$sort': { 'count': -1 } },
		{ $limit: 10 },
		{ $addFields: { sn: '$_id.x', siteId: '$_id.siteId', sitename: '$_id.sitename', country: '$_id.country', isConnected: '$_id.isConnected', subtitle: '$_id.y', id: '$_id.k', } }
	];
	if(showmodel[0] !== ''){
		aggregate.unshift( { $match: { 'model': { $in: showmodel } } } )	
	}
	return {
		type: 'product',
		aggregate
	};
};

const AggrWarrantyDashBoard = (month, isCount) => {
	const showmodel = store.getState().ProgramReducer.showmodel;
	let aggregate = [
		{
			$match: showmodel[0] !== '' ? {
				'siteId': { '$ne': "" },
				'waranties.0': { '$exists': true },
				'model': { $in: showmodel }
			} : {
				'siteId': { '$ne': "" },
				'waranties.0': { '$exists': true }
			}
		},
		{
			'$project': {
				'sn': 1,
				'model': 1,
				'option': 1,
				'installedDate': 1,
				'shipmentdate': 1,
				'siteId': 1,
				'companyId': 1,
				'product_warranty': {
					'$filter': {
						'input': '$waranties',
						'as': 'product_warranty',
						'cond': {
							'$eq': [
								'$$product_warranty.dest', 'warproduct_product'
							]
						}
					}
				},
				'main_parts_warranty': {
					'$filter': {
						'input': '$waranties',
						'as': 'main_parts_warranty',
						'cond': {
							'$ne': [
								'$$main_parts_warranty.dest', 'warproduct_product'
							]
						}
					}
				}
			}
		},
		{
			'$project': {
				'sn': 1,
				'model': 1,
				'option': 1,
				'installedDate': 1,
				'shipmentdate': 1,
				'siteId': 1,
				'companyId': 1,
				'pw': {
					'$max': '$product_warranty.period'
				},
				'mw': {
					'$max': '$main_parts_warranty.period'
				}
			}
		},
		{
			'$lookup': {
				'from': 'sites',
				'localField': 'siteId',
				'foreignField': 'siteId',
				'as': 'sites'
			}
		},
		{
			'$unwind': '$sites'
		},
		{
			'$lookup': {
				'from': 'companies',
				'localField': 'sites.companyId',
				'foreignField': 'companyId',
				'as': 'company'
			}
		},
		{
			'$unwind': '$company'
		},
		{
			'$project': {
				'sn': 1,
				'model': 1,
				'option': 1,
				'installedDate': 1,
				'shipmentdate': 1,
				'sites.sitename': 1,
				'company.name': 1,
				'company.country': 1,
				'pw': 1,
				'mw': 1
			}
		},
		{
			'$match': {
				'pw': {
					$lte: moment.utc(moment().utc()).add(month, 'months').unix() * 1000,
					$gte: moment.utc(moment().utc()).unix() * 1000
				}
			}
		},
		{
			'$sort': {
				'pw': 1
			}
		}
	]

	if (isCount) {
		aggregate = [...aggregate, { $count: 'count' }]
	}

	return {
		type: 'product',
		aggregate
	}
}

export {
	AggrProductCountAll,
	AggrProductCountInstalled,
	AggrProductNewInstalled,
	AggrNewInstalledByCountry,
	AggrProductsInstalledLastNDays,
	AggrAcqLastNDays,
	AggrErrorTotalCount,
	AggrErrorOpenCount,
	AggrErrorClosedCount,
	AggrErrorLastNDays,
	AggrErrorNotCloseCount,
	AggrErrorNotClose,
	AggrErrorClosedLastest,
	AggrErrorNotCloseLastNDays,
	AggrErrorNotCloseLastNHours,
	AggrErrorOpenLastest,
	AggrAcqChartLastNDays,
	AggrErrorChartLastNDays,
	AggrLatestAcqProduct,
	AggrLatestOpenErrors,
	AggrModelPercent,
	AggrModelPercentByNDays,
	AggrErrorPercentLastNDays,
	AggrLatestInstalled,
	AggrErrorCounts,
	AggrModelPercent2,
	AggrProductService,
	AggrLatestAcqProduct2,
	AggrTotalProductByCountry,
	AggrProductCountry,
	AggrProductInstallationsByCountry,
	AggrProductInstallationByCountryLastNdays,
	AggrErrorCodeLastNDays,
	AggrServiceOverdueOnlyCount,
	AggrErrorByCodeLastNDays,
	AggrAllErrCount,
	AggrBeginEndDiff,
	AggrLatestErrorProduct2,
	AggrLatestOpenErrorProduct2,
	AggrErrorPerModel,
	AggrLatestInstalledSite,
	AggrErrorCountsSite,
	AggrProductServiceSite,
	AggrLatestAcqProductSite2,
	AggrDIMDOACountPrd,
	AggrLatestInstalledPrd,
	AggrInventoryProductCount,
	AggrInventoryProduct,
	AggrInventoryProductByCountry,
	AggrLongUsePrd,
	AggrServiceTotalTicketCount,
	AggrServiceCountByStatus,
	AggrServiceLastestOpenTickets,
	AggrServiceLastestClosedTickets,
	AggrServiceOpenCount,
	AggrServiceTotalClosedCount,
	ServiceTotalEscalatedCount,
	AggrServiceChartLastMonth,
	AggrEMSNewOld,
	AggrServiceReplacementTracking,
	AggrServiceReplacementPortion,
	AggrServiceResolve3Step,
	AggrWarrantyDashBoard
};