import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DeviceModels, LanguageCode } from '../../config';
import { ErrorCodeAction } from '../../actions';
import { RayForm } from '../common';
import moment from 'moment';
import { FileAddList } from '../common/FileAddList';
import { Translate as t } from '../../config/Translate';
import { mongo, Mongoose } from 'mongoose';
import { Loading } from '../common/Loading';

const ErrorCodeInfo = ({ su, errorCodeInfo, Update, readonly = false }) => {

    const [newAttachment, setNewAttachment] = useState({ title: "", path: "" });
    const [addAttachment, setAddAttachment] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [solutions, setSolutions] = useState([]);
    const [usersolutions, setUserSolutions] = useState([]);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {        
        
        console.log("errorCodeInfo : ====", errorCodeInfo);

        errorCodeInfo.attachments && setAttachments(errorCodeInfo.attachments);
        errorCodeInfo.solutions && setSolutions(errorCodeInfo.solutions);
        errorCodeInfo.userSolutions && setUserSolutions(errorCodeInfo.userSolutions);
    }, [errorCodeInfo])

    const insertSolution = async (e) => {
        if (e.key === 'Enter') {
            setLoading(true);
            const newSolution = {
                title: e.target.value.trim(),
                useCount: 0,
                resolvedCount: 0,
                registered: new Date().getTime()
            };
            e.target.value = "";
            try {
                const ret = await updateErrorCode({ ...errorCodeInfo, solutions: [...errorCodeInfo.solutions, newSolution] });

                console.log("inert Solution : ====", ret);
            } catch (error) {
                console.log("insertSolution Error : ", error);
            }
            setLoading(false);
        }
    }

    const insertAttachment = () => {
        if (newAttachment.path != "" && newAttachment.title != "") {
            updateErrorCode({ ...errorCodeInfo, attachments: [...errorCodeInfo.attachments, newAttachment] });
            setNewAttachment({ title: "", path: "" });
            setAddAttachment(false);
        }
    }

    const reOrder = (index, delta) => {
        var newIndex = index + delta;
        if (newIndex < 0 || newIndex == solutions.length) return;
        var indexes = [index, newIndex].sort();
        solutions.splice(indexes[0], 2, solutions[indexes[1]], solutions[indexes[0]]);
        updateErrorCode({ ...errorCodeInfo, solutions: [...solutions] });
    };

    const delitem = (idx) => {
        setLoading(true);
        errorCodeInfo.solutions.splice(idx, 1);
        updateErrorCode({ ...errorCodeInfo, solutions: errorCodeInfo.solutions });
        setLoading(false);
    };

    const delAttachment = (idx) => {
        errorCodeInfo.attachments.splice(idx, 1);
        updateErrorCode({ ...errorCodeInfo, attachments: errorCodeInfo.attachments });
    };

    const handleChangeAttachInfo = (e) => {
        const { name, value } = e.target;
        setNewAttachment({ ...newAttachment, [name]: value });
    }

    //Solution Chage & Update
    const handleChangeTitle = (idx, e) => {
        solutions[idx].title = e.target.value;
        setSolutions([...solutions]);
    }

    const handleUpdateTitle = (idx, e) => {
        solutions[idx].title = e.target.value;
        e.target.value = "";
        updateErrorCode({ ...errorCodeInfo, solutions: [...solutions] });
    }

    const setNewFile = (files) => {
        const addNewFile = files.find(f => !f._id) || false;
        if (addNewFile) {
            updateErrorCode({name: addNewFile?.name, path: addNewFile?.path}, "attachment");
        } else {
            updateErrorCode({ attachments: [...files] });
        }
    }

    const handleUpdateData = (e) => {
        const { name, value } = e.target;
        updateErrorCode({ [name]: value });
    }

    const updateErrorCode = (data, type = "update") => {
        console.log("updateErrorCode : ====", data);
        Update(errorCodeInfo.errorCode, errorCodeInfo.lang, data, type);
    }

    const form_controls = [
        {
            title: t('information'), items: [
                {
                    type: "select2", name: "model", label: t('model'), size: 12,
                    val: errorCodeInfo.model,
                    list: DeviceModels,
                    isMulti: true,
                    required: true,
                    isEditable: (su && !readonly)
                },
            ]
        },
        {
            title: "", items: [
                {
                    type: "input", name: "definition", label: t('definition'), isEditable: (su && !readonly),
                    val: errorCodeInfo.definition, size: 12
                },
            ]
        },
        {
            title: "", items: [
                {
                    type: "input", name: "cause", label: t('causeofproblem'), isEditable: (su && !readonly),
                    val: errorCodeInfo.cause, size: 12
                },
            ]
        },
    ]

    return (
        <>
        {!loading && <>
            <RayForm
                isForm={(su && !readonly)}
                controls={form_controls}
                handleUpdateData={handleUpdateData} />
            <div className="section mt-1">
                <div className="section-title">{t('solutions')}</div>
                {(su && !readonly) &&
                    <div className="">
                        {solutions && solutions.map((s, idx) => {
                            return (
                                <div className="input-group input-group-sm mb-1">
                                    <input type="text" value={s.title} className="form-control"
                                        style={{ borderRight: "0px" }}
                                        onChange={(e) => handleChangeTitle(idx, e)}
                                        onBlur={(e) => handleUpdateTitle(idx, e)} />
                                    <div className="ps-2 pe-2 input-group-text border-top border-bottom" style={{
                                        height: "initial",
                                        background: "#fff",
                                        borderColor: "#ced4da !important"
                                    }}>
                                        <div className="chip chip-media">
                                            <i className="chip-icon bg-primary">
                                                <ion-icon name="thumbs-up-outline" role="img" class="md hydrated" aria-label="person"></ion-icon>
                                            </i>
                                            <span className="chip-label">{s.resolvedCount}</span>
                                        </div>
                                    </div>
                                    {idx > 0 &&
                                        <button className="btn btn-secondary mt-0" onClick={() => reOrder(idx, -1)}>
                                            <span className="material-icons fs-16">expand_less</span>
                                        </button>
                                    }
                                    {idx < errorCodeInfo.solutions.length - 1 &&
                                        <button className="btn btn-secondary mt-0" onClick={() => reOrder(idx, 1)}>
                                            <span className="material-icons fs-16">expand_more</span>
                                        </button>
                                    }
                                    <button className="btn btn-danger mt-0" onClick={() => delitem(idx)}>
                                        <span className="material-icons text-white">delete</span>
                                    </button>
                                </div>
                            )
                        })}
                        <div className="list-group-item p-0 mt-1">
                            <input type="text" className="form-control bd0" placeholder="Add New Solution"
                                onKeyPress={(e) => insertSolution(e)} />
                        </div>
                        {errorCodeInfo.solutions.length == 0 && <span className='alert'>No Solution</span>}
                    </div>
                }
                {(!su || readonly) &&
                    <div className="card">
                        <ul className="listview simple-listview inset">
                            {solutions && solutions.map((s, idx) => {
                                return (
                                    <li className="border-bottom">
                                        <div className="float-start">{s.title}</div>
                                        <div className="float-end">
                                            <div className="chip chip-media">
                                                <i className="chip-icon bg-primary">
                                                    <ion-icon name="thumbs-up-outline" role="img" class="md hydrated" aria-label="person"></ion-icon>
                                                </i>
                                                <span className="chip-label">{s.resolvedCount}</span>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                        {errorCodeInfo.solutions.length == 0 && <span className='alert'>No Solution</span>}
                    </div>
                }
                {(su && usersolutions.length > 0) &&
                    <div className="section-title clearfix">
                        <div className="section-title">User Solution</div>
                        <div className="card">
                            <div className="timeline ms-3">
                                {usersolutions.map((c, idx) => (
                                    <div className="item" key={idx}>
                                        <div className="dot bg-primary"></div>
                                        <div className="content">
                                            <div className="title">
                                                {c.adminid} <span className="text-muted">{moment.utc(c.createDate).local().fromNow()}{' '}</span>
                                            </div>
                                            <div className="text">
                                                <i>{c.title}</i>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>}
                <div className="section-title clearfix">
                    <div className="section-title">{t('attachments')}</div>
                    <FileAddList itemlist={attachments} basepath="errorcodes/" callback={setNewFile} cssBtn={""} readonly={!su} />
                    {attachments.length == 0 && <span className='alert'>No Attachment</span>}
                </div>
            </div>
        </>}
        {loading && <Loading />}
        </>
    );
};

const mapState = (state) => {
    const su = state.AuthReducer.su;
    return { su };
};

const mapDispatch = dispatch => ({
    Update: (errorCode, lang, errorCodeInfo, type) => dispatch(ErrorCodeAction.Update(errorCode, lang, errorCodeInfo, type)),
})

export default connect(mapState, mapDispatch)(ErrorCodeInfo);