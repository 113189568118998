import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RayTable, RayTreeview, SiteListHeader } from '../../components/common';
import { ProgramConstant } from '../../actions/constants/ProgramConstant';
import { SiteAction } from '../../actions/SiteAction';
import CustomHeader from '../../components/common/CustomHeader';
import { CustomFieldAction } from '../../actions';
import { Translate as t } from '../../config/Translate';

const AdminSiteList = ({ customs, user, GetSites, sites, totalSiteCount, filterOptions, filterDateOptions, companies, viewTree }) => {

	const [wHg, setwHg] = useState(300);
	const [wAll, setwAll] = useState(window.innerWidth);
	const [filterCompanyId, setFilterCompanyId] = useState("");
	const [filterCompany, setFilterCompany] = useState({ name: "ALL" });

	const [exheader, setExHeader] = useState([]);
	const [header, setHeader] = useState([]);
	const [ready, setReady] = useState(false);
	const [showCHModal, setShowCHModal] = useState(false);
	const [dfilters, setdFilters] = useState(false);
	const dispatch = useDispatch();

	const filterpage = {
		filters: {},
		linkto: '/Admin/AdminSiteInfo',
		linkkey: 'name',
		filterOptions: ['country'],
		filterDateOptions: filterDateOptions || [{ code: 'createDate', label: 'Registerd' }],		
		searchCols: ['country', 'sitename'],
		searchKey: "",
		page: 1,
		itemperpage: 30
	};

	useEffect(() => {
		user.adminType != "U" && updateViewTree(true);
	}, []);

	useEffect(() => {
		const newfilters = [];
		customs.filter(m => m.group.includes("SERVICE")).map(x => {
			if (x.valuetype == "FIXLIST") {
				var mList = [];
				x.listvals.map(l => mList.push({ code: l, title: l }));
				newfilters.push({ code: "customfieldvals." + x.code, type: x.valuetype, name: x.title, fixlist: mList });
			} else {
				newfilters.push({ code: "customfieldvals." + x.code, type: x.valuetype, name: x.title });
			}
		});
		setdFilters(newfilters);
	}, [customs]);

	useEffect(() => {
		setReady(header.length > 0);
	}, [header]);

	useEffect(() => {
		setHeader(exheader.length > 0 ? SiteListHeader.concat(exheader) : SiteListHeader);
	}, [exheader]);

	useEffect(() => {
		if (dfilters.length > 0) {
			setExHeader(user.adminheaders.sites ? user.adminheaders.sites.filter(x => dfilters.map(m => m.code).includes(x.name)) : []);
		} else {
			setExHeader([]);
		}
	}, [user.adminheaders.sites, dfilters]);

	useEffect(() => { console.log('sites', sites) }, [sites]);

	const options = {
		search: true,
		keyCol: "_id",
		linkto: '/Admin/AdminSiteInfo',
		linkkey: 'sitename',
		filters: filterpage.filters,
		filterpageoption: filterpage,
		getList: GetSites,
		itemperpage: filterpage.itemperpage,
		useapi: true,
		totalitemcount: totalSiteCount,
		filterOptions: filterOptions
	}

	const getWidth = () => setwAll(window.innerWidth);

	useEffect(() => {
		if (viewTree) {
			setwHg(300);
		} else {
			setwHg(0);
		}
	}, [viewTree]);

	useEffect(() => {
		window.addEventListener('resize', setwAll(window.innerWidth));
		setwAll(window.innerWidth);
		//GetCustomAll();
	}, []);

	const onChangeTreeviewItem = (item) => {
		setFilterCompanyId({ companyId: item.id });
		setFilterCompany(item);
	}

	const updateViewTree = (bl) => {
		dispatch({ type: ProgramConstant.VIEWTREE, val: bl });
	}

	return (
		<>
			<div className="extraHeader">
				<h2 className="mb-0">{t('site')}
					{companies.length > 1 && <button className="btn btn-secondary btn-sm mt-0 ms-1" onClick={() => updateViewTree(!viewTree)}>
						{filterCompany.name && <>
							{filterCompany.country && "[" + filterCompany.country + "] "}{filterCompany.name}
						</>}
					</button>}
				</h2>
				<div className="ray-right">
					<button onClick={() => setShowCHModal(true)} className="btn btn-sm mt-0">
						<ion-icon name="options-outline"></ion-icon> {t('customheaders')}
					</button>
					<Link to="/Admin/AdminSiteDashboard" className="btn btn-sm mt-0">
						<ion-icon name="desktop-outline"></ion-icon> {t('dashboard')}
					</Link>
					<Link to="/Admin/AdminSiteAdd" className="btn btn-primary btn-sm mt-0">
						<ion-icon name="add-outline"></ion-icon>
						{t('addsite')}
					</Link>
				</div>
			</div>
			<div id="appCapsule" className="extra-header-active full-height">
				{(viewTree && user.adminType != "U") &&
					<div className={viewTree ? "section fl p-0" : "section p-0 fl"} style={{ width: wHg }}>
						<div className='p-1'>
							<RayTreeview onClickItem={onChangeTreeviewItem} />
						</div>
					</div>}
				{ready && <div className="section m-1 p-0">
					<div className="card p-2">
						<div className={user.adminType != "U" ? "fl" : ""} >
							<RayTable
								// data={sites?.map(x => {
								// 	return {
								// 		...x,
								// 		country: x.countryCode,
								// 		sitename: x.name,
								// 		customername: x?.customer?.firstname,
								// 		createDate: parseInt(x.created)
								// 	}
								// })}
								data={sites}
								listtype="site"
								addFilter={filterCompanyId}
								columns={header}
								options={options}
								apiPath='/site'
							/>
						</div>
						<div className="clearfix"></div>
					</div>
				</div>}
			</div>
			<CustomHeader
				extratrakey={"SITEEXTRAKEY"}
				title={"Site List Header"}
				extratrakeyname={"extradata"}
				exHeader={exheader}
				adminheaderprefix={"sites"}
				showModal={showCHModal}
				callbackClose={() => setShowCHModal(false)}
				defaultFilter={dfilters || []} />
		</>
	)
}

const mapState = (state) => {
	var user = state.AuthReducer.user;
	var sites = state.SiteReducer.sites;
	var viewTree = state.ProgramReducer.viewtree;
	var companies = state.CompanyReducer.companies;
	var totalSiteCount = state.SiteReducer.totalSiteCount;
	var filterOptions = state.SiteReducer.filterOptions;
	var filterDateOptions = state.SiteReducer.filterDateOptions;
	const customs = state.CustomFieldReducer.items;
	return { customs, sites, totalSiteCount, filterOptions, filterDateOptions, user, viewTree, companies };
}

const mapDispatch = dispatch => ({
	GetSites: (filter) => dispatch(SiteAction.GetSites(filter)),
	//GetCustomAll: () => dispatch(CustomFieldAction.GetAll()),
})

export default connect(mapState, mapDispatch)(AdminSiteList)
