import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { CommonAction } from '../actions/CommonAction';
import { isDIM, isDOA, isCon } from '../components/common/CommonViews';
import { CountryCode } from '../config/CountryCode';
import { Loading } from '../components/common/Loading';
import { ServiceStatusChip } from '../components/service/ServiceCard';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ProductPartAction } from '../actions/ProductPartAction';
import { RayCodeAction } from '../actions/RayCodeAction';
import { Translate as t } from '../config/Translate';
import { RayStatuses } from '../config/RayCodes';

const WidgetCustomTable = ({ user, headers, title, aggregate, linkKey, appendix = "", limit, GetAllParts, partitems, xtype, clist,
	txtcolor = "default", bgcolor = "default", basePath = "", basePathKey = "", height = null, setting = null }) => {

	const [list, setList] = useState([]);
	const [isFetch, setIsFetch] = useState(true);
	const [hide, setHide] = useState(false);
	const [sortKey, setSortKey] = useState("");
	const [sortList, setSortList] = useState([]);
	const [trackservices, settrackservices] = useState([]);

	useEffect(() => {
		checkSort();
	}, [])

	useEffect(async () => {
		await getData();
	}, [aggregate])

	useEffect(() => {
		if (partitems.length == 0 && headers.filter(x => x.type == "PARTS").length > 0) {
			GetAllParts();
		}
		if (partitems.length == 0 && headers.filter(x => x.type == "TRACKNO").length > 0) {
			settrackservices(clist?.filter(x => x.type === 'TRACKING_PATH'));
			// RayCodeAction.GetAllByFilter({ type: "TRACKING_PATH" }).then(x => {
			// 	settrackservices(x);
			// });
		}
	}, [headers, clist])

	useEffect(async () => {
		await getData();
	}, [sortKey])

	const doSort = (e) => {
		setSortKey(e.target.value);
	}

	const checkSort = () => {
		var soptions = [];
		var nowsort = "";
		headers.filter(x => x.sortoption).map(x => {
			soptions.push({ code: x.name, value: x.label });
			if (x.sort)
				nowsort = x.name;
		});
		if (sortKey != nowsort) {
			setSortKey(nowsort);
			setSortList(soptions);
		}
	}

	const sortChange = (aggrOriginal) => {
		var aggr = [...aggrOriginal.aggregate];
		if (sortKey) {
			for (var i in aggr) {
				if (aggr[i].hasOwnProperty("$sort")) {
					var obj = {};
					obj[sortKey] = -1;
					aggr[i] = { "$sort": obj };
				}
			}
			return { ...aggrOriginal, aggregate: aggr };
		}
		return aggrOriginal;
	}

	const getData = () => {
		// const ret = await CommonAction.GetCountAction(sortChange(aggregate));
		CommonAction.GetCountAction(sortChange(aggregate)).then(ret => {
			if (xtype == "abc") {
				var nlist = [];
				ret.data.map(x => {
					if (x.hasOwnProperty('a'))
						nlist.push({ abc: '~ 1 day', value: x.a.length > 0 ? x.a[0].count : 0 });
					if (x.hasOwnProperty('b'))
						nlist.push({ abc: '1 ~ 3 day', value: x.b.length > 0 ? x.b[0].count : 0 });
					if (x.hasOwnProperty('c'))
						nlist.push({ abc: '3  ~ 7 day', value: x.c.length > 0 ? x.c[0].count : 0 });
					if (x.hasOwnProperty('d'))
						nlist.push({ abc: '7 day ~ ', value: x.d.length > 0 ? x.d[0].count : 0 });
				})
				setList(nlist);
			} else {
				setList(ret.data);
			}

			setIsFetch(false);
		});
	}

	const getItemText = (item, key, col) => {
		try {
			var r = '';
			if (key.indexOf('.') > -1) {
				var arr = key.split('.');
				r = item[arr[0]][arr[1]];
			} else {
				r = item[key];
			}
			if (basePath && linkKey && linkKey == key) {
				r = r && <Link to={basePath + r + (appendix && appendix)}>{r}</Link>;
			} else if (col.type == "DT") {
				r = r ? moment.utc(r).local().format('YYYY-MM-DD HH:mm:ss') : "";
			} else if (col.type == "HDT" || col.type == "DURATION") {
				r = r ? moment.utc(r).local().fromNow() : "";
			} else if (col.type == "DTONLY") {
				r = r ? moment.utc(r).local().format('YYYY-MM-DD') : "";
			} else if (col.type == "NUMBER") {
				r = r !== "" ? r.toLocaleString() : "";
			} else if (col.type == "PROGRESS") {
				r = getProg(item, key, col);
			} else if (col.type == "SPAN" && Number.isInteger(parseInt(r))) {
				r = parseInt(r).toLocaleString();
			} else if (col.link && !col.linkdata) {
				r = <Link to={basePath + r + (appendix && appendix)}>{r}</Link>;
			} else if (col.name == "sn" || col.type == "SN") {
				r = <Link to={"/Admin/AdminProductInfo/" + r}>{isCon(item, 16)}{r} {isDOA(item)} {isDIM(item)}</Link>;
			} else if (col.name == 'sitename' || col.name == 'site.sitename') {
				r = <Link to={`/Admin/AdminSiteInfo/${item[col.linkdata]}`}>{r}</Link>;
			}
			else if (col.type == "STATUS") {
				r = <ServiceStatusChip item={{ status: r }} />;
			} else if (col.type == "TRACKNO") {
				var tservice = "";
				if (col.trackservicekey != "") {
					if (col.trackservicekey.indexOf('.') > -1) {
						var arr = col.trackservicekey.split('.');
						tservice = item[arr[0]][arr[1]];
					} else {
						tservice = item[col.trackservicekey];
					}
				}
				if (tservice) {
					var n = trackservices.filter(x => x.name == tservice);
					if (n.length > 0) {
						r = <a href={n[0].code + r} target='_blank'>{r}</a>
					}
				} else {
					r = r && <a href={'https://t.17track.net/ko#nums=' + r} target='_blank'>{r}</a>;
				}
			} else if (col.type == "PARTS") {
				var n = partitems.filter(x => x.code == r);
				if (n.length > 0) {
					r = <>{n[0].name}</>
				}else{
					r = 'Other';
				}
			} else if (col.type == "COUNTRY") {
				const cname = CountryCode.find(item => r == item.code).title;
				r = <><OverlayTrigger
					placement={'top'}
					overlay={<Tooltip> {cname} </Tooltip>}
				>
					<img src={"/assets/img/flags/" + r + ".png"} style={{ height: 24 }} alt={cname} />
				</OverlayTrigger>
					{col.showtype != "ONLYFLAG" && <>&nbsp;{cname}</>}
				</>
			} else if (col.type == "STATUSTYPE") {
				const statusName = RayStatuses.find(item => r == item.code).title;
				return statusName || r;
			}
			return r;
		} catch { }
		return "";
	};

	const getProg = (item, key, col) => {
		const max = Math.max.apply(Math, list.map(function (o) { return o[col.value]; }));
		const colors = ["bg-primary", "bg-success", "bg-warning", "bg-danger"];
		if (col.values) {
			return (
				<div className="progress" style={{ height: 24 }}>
					{col.values.map((x, idx) => {
						const val = item[x];
						const per = Math.round(val * 100 / max);
						return <div className={"progress-bar " + colors[idx]}
							style={{ width: per + "%", height: 24 }}
							aria-valuenow={per}
							aria-valuemin="0" aria-valuemax="100">{user.settings.dashboardshowprogval == "Y" && item[x]}</div>
					})}
				</div>
			)
		} else {
			const val = item[col.value];
			const per = Math.round(val * 100 / max);
			return <div className="progress" style={{ height: 24 }}>
				<div className="progress-bar bg-primary"
					style={{ width: per + "%", height: 24 }}
					aria-valuenow={per}
					aria-valuemin="0" aria-valuemax="100">{user.settings.dashboardshowprogval == "Y" && item[col.value]}</div>
			</div>
		}
	}

	const rowSelectCallback = (x) => {
	}
	var lcount = 0;

	return (
		<div className="mb-2">
			<div className={"card"}>
				{list && <div className="card-header">
					{/* <div className="card-title mb-0 float-start" onClick={() => setHide(!hide)}> */}
					<div className="card-title mb-0 float-start">
						<div className="all-caps">
							{title}{limit && "(" + list.length.toLocaleString() + ")"}{(!limit && hide) && " (" + list.length + ")"}

						</div>
					</div>
					<div className="float-end">
						{sortList.length > 0 && <select onChange={(e) => doSort(e)}
							value={sortKey} className="me-1  form-control-sm float-start">
							{sortList.map(x => <option value={x.code}>{x.value}</option>)}
						</select>}
						{setting && <a className='btn btn-sm mt-0 right' onClick={setting}><ion-icon name="settings-outline"></ion-icon></a>}
					</div>
				</div>}
				{!hide && <>
					{isFetch && <Loading />}
					{!isFetch &&
						// {
						<table className="table" style={height ? { display: 'flex', flexFlow: 'column', width: '100%' } : {}}>
							<thead style={height ? { flex: '0 0 auto' } : {}}>
								<tr style={height ? { width: '100%', display: 'table', tableLayout: 'fixed' } : {}}>
									{headers.map((col) => {
										return (
											<th key={col.name} className={col.align && col.align}
												style={col.style ? col.style : {}} >
												{col.label}
											</th>
										);
									})}
								</tr>
							</thead>
							<tbody style={height ? { display: 'block', flex: '1 1 auto', height, overflow: 'auto' } : {}}>
								{list && list.map((item, index) => {
									if (limit) {
										if (limit <= lcount)
											return;
									}
									lcount++;
									return (
										<tr key={index} onClick={() => rowSelectCallback(item)}
											style={height ? { height: 49, width: '100%', display: 'table', tableLayout: 'fixed' } : {}}>
											{headers.map((col, idx) =>
												<td key={col.name + idx} className={col.align && col.align} style={col.style ? col.style : {}}>
													{getItemText(item, col.name, col)}
												</td>
											)}
										</tr>
									);
								})}
								{!isFetch && (!list || list.length == 0) && <tr>
									<td colSpan={headers.length} className="text-center" style={{ width: '1000px' }}>
										<i>{t('norecords')}</i>
									</td>
								</tr>}
							</tbody>
						</table>
					}
				</>}
			</div>
		</div >
	);
};

const mapState = (state) => {
	var user = state.AuthReducer.user;
	var partitems = state.ProductPartReducer.items;
	const clist = state.RayCodeReducer.items;
	return { partitems, user, clist };
};

const mapDispatch = (dispatch) => ({
	GetAllParts: (filter) => dispatch(ProductPartAction.GetAll(filter)),
});

export default connect(mapState, mapDispatch)(WidgetCustomTable);
