import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { ProductAction, AuthAction } from '../../actions';
import {
	ProductInfo,
	ProductInfoACQ,
	ProductComments,
	ProductDelete,
	ProductInfoSite,
	ProductInfoWaranty,
} from '../../components/product'
import { CalendarConstant } from '../../actions/constants/CalendarConstant';
import ProductErrorList from '../../components/product/ProductErrorList';
import { ProductStatus } from '../../components/product/ProductStatus';
import { AppHeader } from '../../components/common';
import { isDOA, isDIM } from '../../components/common/CommonViews';
import ScheduleCreate from '../../components/schedule/ScheduleCreate';
import ProductErrorCard from '../../components/product/ProductErrorCard';
import { NoRecord } from '../../pages/HomePage';
import moment from 'moment';
import ProductInfoServices from '../../components/product/ProductInfoServices';
import ProductInfoErrorChart from '../../components/product/ProductInfoErrorChart';
import ProductDIM from '../../components/product/ProductDIM';
import ProductDOA from '../../components/product/ProductDOA';
import { Translate as t } from '../../config/Translate';

const ProductView = ({ GetProduct, UpdateUserFav, productData, favList, _sn, showChart, showSub, chartType }) => {

	const dispatch = useDispatch();
	const [showAS, setShowAS] = useState(false);
	const { sn, view } = useParams();
	const [maintab, setMainTab] = useState(view || "info");
	const [isLoading, setIsLoading] = useState(true);
	const [editable, setEditable] = useState(false);
	const [errList, setErrList] = useState([]);
	const [isFav, setIsFav] = useState(false);
	const [product, setProduct] = useState({});
	const [showDIADOA, setshowDIADOA] = useState("COMMENT");

	useEffect(() => {
		console.log(product);
		setIsLoading(false)
		getErrList();
		setIsFav(favList ? favList.includes(product.sn) : false);
	}, [product])

	useEffect(() => {
		if(productData?.sn) {
			console.log("productData : 0======", productData);
			setProduct(productData);
		}
	}, [productData])

	useEffect(() => {
		setIsFav(favList ? favList.includes(product.sn) : false);
	}, [favList])

	useEffect(() => {
		if (_sn) {
			console.log("_sn : _sn : _sn : _sn : _sn : _sn : _sn : _sn : ", _sn);
			GetProduct(_sn);
		}

			
	}, [_sn])

	const mainTabList = [
		{ code: "info", title: t("overview"), icon: "info" },
		{ code: "service", title: t('service'), icon: "visibility" },
		{ code: "error", title: t('error'), icon: "info" },
		{ code: "acquisition", title: t('acquisition'), icon: "visibility" },
		//{ code : "sch", title : "Schedule", icon : "visibility" },
	]

	const removeArr = (id) => {
		const idx = favList.indexOf(id);
		if (idx > -1) {
			favList.splice(idx, 1);
		}
		return favList;
	}

	const fav = () => {
		UpdateUserFav("sn", isFav ?
			{ favorites: removeArr(product.sn) } :
			{ favorites: [...favList, product.sn] });
	}
	const getErrList = () => {
		const list = [];
		if (product.errs) {
			product.errs.map(x => {
				list.push({ ...product, errs: x, acquisition: [] });
			});
		}
		setErrList(list.sort((a, b) => b.errs.begin - a.errs.begin));
	}

	const setSchedule = () => {
		dispatch({
			type: CalendarConstant.SET_NEW, item: {
				type: "On-site",
				color: "bg-success",
				start: moment().clone().add(4, "hours").valueOf(),
				finish: moment().clone().add(5, "hours").valueOf(),
				sn: product.sn,
				sitename: product.sitename,
				siteId: product.siteId,
				model: product.model,
				option: product.option
			}
		});
		setShowAS(true);
	}

	const isOnline = () => {
		return product.isConnected ?
			<ion-icon name="wifi" className="text-primary md hydrated"></ion-icon> :
			<ion-icon name="wifi-outline" className="text-muted md hydrated"></ion-icon>;
	}

	const [height, setvHg] = useState(300);
	const [width, setvWh] = useState(800);
	const getHeight = () => setvHg(window.innerHeight);
	const getWidth = () => setvWh(window.innerWidth);
	useEffect(() => {
		window.addEventListener('resize', getHeight);
		window.addEventListener('resize', getWidth);
		setvHg(window.innerHeight);
		setvWh(window.innerWidth);
	}, [])

	const sortErr = (errs) => errs.sort((a, b) => a.begin > b.begin ? 1 : -1);

	const getScrollHeight = () => {
		var min = 72 + 72;
		if (showChart && ChartHasVal())
			min += 310;
		if (showSub)
			min += 72;
		return height - min;
	}

	const ChartHasVal = () => {
		if (chartType == "acq" && product.acquisitions) {
			return product.acquisitions.filter(x => x.activationTime > moment().add(-1 * 60, 'days')).length > 0;
		}
		if (chartType == "err" && product.errs) {
			return product.errs.filter(x => x.begin > moment().add(-1 * 60, 'days')).length > 0;
		}
		return false;
	}

	return (
		<>
			{!_sn && <div className="appHeader no-border">
				<AppHeader
					title={<>{product.sn}{isDOA(product)}{isDIM(product)}</>}
					titleprefix={isOnline()}
					subtitle={product.sitename && product.sitename} />

				<div className="right">
					<a href onClick={() => setSchedule()} className="headerButton">
						<ion-icon name="calendar-outline"></ion-icon>
					</a>
					{maintab == "info" && <a href onClick={() => setEditable(!editable)} className="headerButton">
						<ion-icon name="create-outline"></ion-icon>
					</a>}
					<a href onClick={() => fav()} className="headerButton">
						<ion-icon name={isFav ? "heart" : "heart-outline"}></ion-icon>
					</a>
				</div>
			</div>}
			<div id="appCapsule" style={
				_sn ? {
					position: "absolute",
					paddingTop: "0px",
					width: "400px",
					top: (showSub ? "120px" : "60px"),
					right: "8px",
				} : {}
			}>
				<div className={_sn ? "section" : "section mb-2"}>
					<ul className="nav nav-tabs style2 mt-1 mb-1"
						style={_sn && {
							boxShadow: "4px 4px 4px darkgrey"
						}}>
						{mainTabList.filter(y => _sn ? y.code != "acquisition" : y = y).map(x =>
							<li className="nav-item">
								<button key={x.code}
									className={maintab == x.code ? "nav-link active" : "nav-link"} onClick={() => { setMainTab(x.code); }}>
									{x.title}
								</button>
							</li>
						)}
					</ul>
				</div>
				{maintab == "info" &&
					<div className="" style={
						_sn ? {
							padding: 0,
							height: getScrollHeight(),
							overflowY: "auto"
						} : {}
					}>
						<ProductInfo productInfo={product} _sn={_sn} editable={editable} showsn={_sn ? true : false} cancelcallback={() => setEditable(false)} />
						{_sn && <div className="pb-1"></div>}
						{product.siteId && <div className="mb-2"><ProductInfoSite _sn={_sn} product={product} showsitename={false} /></div>}
						<div className="section mt-2 mb-2">
							<div className="card" style={_sn && {
								boxShadow: "4px 4px 4px darkgrey"
							}}>
								<ul className="nav nav-tabs lined">
									<li className="nav-item">
										<a href
											className={showDIADOA == "COMMENT" ? "nav-link active" : "nav-link"}
											onClick={() => { setshowDIADOA("COMMENT"); }} >{t('comments')}</a>
									</li>
									<li className="nav-item">
										<a href
											className={showDIADOA == "DIM" ? "nav-link active" : "nav-link"}
											onClick={() => { setshowDIADOA("DIM"); }} >{t('dim')}</a>
									</li>
									<li className="nav-item">
										<a href
											className={showDIADOA == "DOA" ? "nav-link active" : "nav-link"}
											onClick={() => { setshowDIADOA("DOA"); }} >{t('doa')}</a>
									</li>
								</ul>
								{showDIADOA == "COMMENT" && <ProductComments _sn={_sn} product={product} />}
								{showDIADOA == "DIM" && <ProductDIM _sn={_sn} product={product} />}
								{showDIADOA == "DOA" && <ProductDOA _sn={_sn} product={product} />}
							</div>
						</div>
					</div>
				}
				{maintab != "info" &&
					<div className="mt-2 mb-2">
						{maintab == "service" && <ProductInfoServices product={product} _sn={_sn} />}
						{maintab == "error" &&
							<div className="section" style={
								_sn ? {
									height: getScrollHeight(),
									overflowY: "auto"
								} : {}
							}>
								{errList.length > 0 && <>
									{errList.map(item => <ProductErrorCard item={item} key={"err" + item._id} _sn={_sn} />)}
								</>}
								{errList.length === 0 && <NoRecord title={t('noerrors')} _sn={_sn} contents={t('thereisnoerrorhistory')} />}
							</div>
						}
						{/*maintab == "status" && <ProductStatus product={product} editable={editable} /> */}
						{maintab == "acquisition" && <ProductInfoACQ product={product} showtable={false} days={7} height={500} />}
					</div>
				}
			</div>
			{(_sn && showChart) &&
				<div className="card" style={
					_sn ? {
						position: "absolute",
						paddingTop: "0px",
						width: width - 36,
						opacity: 0.9,
						bottom: "18px",
						left: "18px",
						boxShadow: "4px 4px 4px darkgrey",
					} : {}
				}>
					{chartType == "acq" && <ProductInfoACQ product={product}
						showtable={false}
						days={30}
						height={300}
						onlyGraph={true} />
					}
					{chartType == "err" && <ProductInfoErrorChart product={product}
						showtable={false}
						days={60}
						height={300}
						onlyGraph={true} />
					}
				</div>
			}
			<ScheduleCreate showAS={showAS} callback={() => setShowAS(false)} />
		</>
	)
}

const mapState = (state) => {
	var productData = state.ProductReducer.product;
	var showChart = state.ProgramReducer.showChart;
	var showSub = state.ProgramReducer.showSub;
	var chartType = state.ProgramReducer.chartType;
	var favList = state.FavoriteReducer.sn;
	return { productData, favList, showChart, showSub, chartType };
};

const mapDispatch = dispatch => ({
	GetProduct: (sn) => dispatch(ProductAction.GetProduct(sn)),
	UpdateUserFav: (type, data) => dispatch(AuthAction.UpdateUserFav(type, data)),
})

export default connect(mapState, mapDispatch)(ProductView);