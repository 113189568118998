import axios from 'axios';
import { AppConfig, JWT } from '../config';
import { RayDataConstant } from './constants/RayDataConstant';
import { AlertAction } from './AlertAction';

const GetAll = (dataType, companyId = '') => async (dispatch) => {
	dispatch({ type: RayDataConstant.LOADING });

	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/raydata/' + dataType, JWT());

	if (ret.data.status == 'success') {
		let result = [];

		if (companyId) {
			let companyIdList = [];
			const response = await axios.get(`${AppConfig.API_SERVER_PATH}/company/getchildren/${companyId}`);

			if (response.data.status === 'success')
				companyIdList = response.data.data;

			if (companyIdList){
				result = ret.data.data.filter(x => companyIdList.includes(x.companyId) && x.hasOwnProperty('category') && x.hasOwnProperty('standards'));
			}
		} else {
			result = ret.data.data.filter(x=> x.hasOwnProperty('category') && x.hasOwnProperty('standards'));
		}
		

		let items = [];

console.log("result : raydataction : ", result);

		result.forEach(x => {
			//const exists = items.find(y => y.companyId === x.companyId && y.warrantyType === x.wartype && y.models === y.models);
			const exists = items.find(y => y.companyId === x.companyId && y.warrantyType === x.wartype && (y.models.every(md => x.models.includes(md)) && x.models.every(md => y.models.includes(md))));

			if (!exists || !Object.keys(exists).length) {
				items.push({
					_id: x._id,
					companyId: x.companyId,
					warrantyType: x.wartype,
					wartype: x.wartype,
					models: x.models,
					standards : x.standards,

					productWarrantyByInstalledDateHQ: x.category === "HQ" ? x.standards.find(y => y.type === "Product" && y.baseDate === "InstalledDate")?.months : '',
					productWarrantyByShipmentDateHQ: x.category === "HQ" ? x.standards.find(y => y.type === "Product" && y.baseDate === "ShipmentDate")?.months : '',
					mainPartsWarrantyByInstalledDateHQ: x.category === "HQ" ? x.standards.find(y => y.type === "MainParts" && y.baseDate === "InstalledDate")?.months : '',
					mainPartsWarrantyByShipmentDateHQ: x.category === "HQ" ? x.standards.find(y => y.type === "MainParts" && y.baseDate === "ShipmentDate")?.months : '',

					productWarrantyByInstalledDateBranch: x.category === "BRANCH" ? x.standards.find(y => y.type === "Product" && y.baseDate === "InstalledDate")?.months : '',
					productWarrantyByShipmentDateBranch: x.category === "BRANCH" ? x.standards.find(y => y.type === "Product" && y.baseDate === "ShipmentDate")?.months : '',
					mainPartsWarrantyByInstalledDateBranch: x.category === "BRANCH" ? x.standards.find(y => y.type === "MainParts" && y.baseDate === "InstalledDate")?.months : '',
					mainPartsWarrantyByShipmentDateBranch: x.category === "BRANCH" ? x.standards.find(y => y.type === "MainParts" && y.baseDate === "ShipmentDate")?.months : '',
				});
			} else {
				if (exists.category === 'HQ') {
					exists["productWarrantyByInstalledDateHQ"] = exists.standards.find(y => y.type === "Product" && y.baseDate === "InstalledDate").months || '';
					exists["productWarrantyByShipmentDateHQ"] = exists.standards.find(y => y.type === "Product" && y.baseDate === "ShipmentDate").months || '';
					exists["mainPartsWarrantyByInstalledDateHQ"] = exists.standards.find(y => y.type === "MainParts" && y.baseDate === "InstalledDate").months || '';
					exists["mainPartsWarrantyByShipmentDateHQ"] = exists.standards.find(y => y.type === "MainParts" && y.baseDate === "ShipmentDate").months || '';
				}

				if (exists.category === 'BRANCH') {
					exists["productWarrantyByInstalledDateBranch"] = exists.standards.find(y => y.type === "Product" && y.baseDate === "InstalledDate").months || '';
					exists["productWarrantyByShipmentDateBranch"] = exists.standards.find(y => y.type === "Product" && y.baseDate === "ShipmentDate").months || '';
					exists["mainPartsWarrantyByInstalledDateBranch"] = exists.standards.find(y => y.type === "MainParts" && y.baseDate === "InstalledDate").months || '';
					exists["mainPartsWarrantyByShipmentDateBranch"] = exists.standards.find(y => y.type === "MainParts" && y.baseDate === "ShipmentDate").months || '';
				}
			}
		});

		console.log(
			"getall items : ===", items
		);
		dispatch({ type: RayDataConstant.GETALL, items });
	} else {
		dispatch(AlertAction.Error(ret.data.err));
	}
	dispatch({ type: RayDataConstant.LOADED });
};

const GetAllByFilter = async (datatype, filter) => {
	const data = {
		type: 'getfilter',
		data: filter,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/raydata/' + datatype, data, JWT());
	if (ret.data.status == 'success') {
		return ret.data.data;
	} else {
		return [];
	}
};

const GetOne = (datatype, _id) => async (dispatch) => {
	dispatch({ type: RayDataConstant.LOADING });
	const ret = await axios.get(AppConfig.API_SERVER_PATH + '/raydata/' + datatype + '/' + _id, JWT());
	if (ret.data.status == 'success') {
		dispatch({ type: RayDataConstant.GET, item: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data.err));
	}
	dispatch({ type: RayDataConstant.LOADED });
};

const Delete = (datatype, _id) => async (dispatch) => {
	dispatch({ type: RayDataConstant.LOADING });
	const data = {
		type: 'delete',
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/raydata/' + datatype + '/' + _id, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Deleted!"));
		dispatch({ type: RayDataConstant.DELETE, _id: _id });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: RayDataConstant.LOADED });
};

const Create = (datatype, info) => async (dispatch) => {
	dispatch({ type: RayDataConstant.LOADING });
	const data = {
		type: 'create',
		data: info,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/raydata/' + datatype, data, JWT());
	if (ret.data.status == 'success') {
		dispatch(AlertAction.Success("Created!"));
		dispatch({ type: RayDataConstant.CREATE, item: ret.data.data });
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: RayDataConstant.LOADED });
};

const AddUpdate = (info) => async (dispatch) => {
	dispatch({ type: RayDataConstant.ADDUPDATE, item: info });
};

const Update = (datatype, _id, info) => async (dispatch) => {
	dispatch({ type: RayDataConstant.LOADING });
	const data = {
		type: 'update',
		data: info,
	};
	const ret = await axios.post(AppConfig.API_SERVER_PATH + '/raydata/' + datatype + '/' + _id, data, JWT());
	if (ret.data.status == 'success') {
		const data = ret.data.data;

		dispatch(AlertAction.Success("Updated!"));
		dispatch({
			type: RayDataConstant.UPDATE,
			item: {
				...data,
				warrantyType: data.wartype,
				productWarrantyByInstalledDateHQ: data.category === "HQ" ? data.standards.find(y => y.type === "Product" && y.baseDate === "InstalledDate")?.months : '',
				productWarrantyByShipmentDateHQ: data.category === "HQ" ? data.standards.find(y => y.type === "Product" && y.baseDate === "ShipmentDate")?.months : '',
				mainPartsWarrantyByInstalledDateHQ: data.category === "HQ" ? data.standards.find(y => y.type === "MainParts" && y.baseDate === "InstalledDate")?.months : '',
				mainPartsWarrantyByShipmentDateHQ: data.category === "HQ" ? data.standards.find(y => y.type === "MainParts" && y.baseDate === "ShipmentDate")?.months : '',
				productWarrantyByInstalledDateBranch: data.category === "BRANCH" ? data.standards.find(y => y.type === "Product" && y.baseDate === "InstalledDate")?.months : '',
				productWarrantyByShipmentDateBranch: data.category === "BRANCH" ? data.standards.find(y => y.type === "Product" && y.baseDate === "ShipmentDate")?.months : '',
				mainPartsWarrantyByInstalledDateBranch: data.category === "BRANCH" ? data.standards.find(y => y.type === "MainParts" && y.baseDate === "InstalledDate")?.months : '',
				mainPartsWarrantyByShipmentDateBranch: data.category === "BRANCH" ? data.standards.find(y => y.type === "MainParts" && y.baseDate === "ShipmentDate")?.months : '',
			}
		});
	} else {
		dispatch(AlertAction.Error(ret.data));
	}
	dispatch({ type: RayDataConstant.LOADED });
};

const SetWarranty = async (sn, category, warrantyType) => {
	const ret = await axios.post(`${AppConfig.API_SERVER_PATH}/set-warranty`, { sn, category, warrantyType }, JWT());
	return ret.data;
}

export const RayDataAction = {
	GetAll,
	GetOne,
	GetAllByFilter,
	Create,
	Update,
	Delete,
	AddUpdate,
	SetWarranty
};
