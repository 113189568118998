import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { history } from '../../history';
import { ServiceAction, AdminUserAction } from '../../actions';
import { RayForm, RayFormValidation } from '../../components/common';
import ProductErrorInfo from '../../components/product/ProductErrorInfo';
import ProductSimpleInfo from '../../components/product/ProductSimpleInfo';
import SiteInfo from '../../components/site/SiteInfo';
import ServiceClaimCategory from '../../components/service/ServiceClaimCategory';
import { Translate as t } from '../../config/Translate';

const ServiceCreateByErrorCode = ({ service, apierrors, referErrorCode, referProduct,
	referSite, Create, callbackCreated, AddUpdate, withBack = false }) => {
	const isAdmin = location.pathname.toLowerCase().startsWith('/admin/');
	const [isAddProcess, setIsAddProcess] = useState(false);
	const [newService, setNewService] = useState({
		sn: referProduct && referProduct.sn,
		productErrorCodeId: referErrorCode && referErrorCode._id,
		errorCode: referErrorCode && referErrorCode.code,
		siteId: referSite && referSite.siteId,
		effectiveDate: referErrorCode && referErrorCode.begin
	});
	const [errors, setErrors] = useState([]);

	useEffect(() => {
		AddUpdate({});
	}, [])

	useEffect(() => {
		if (isAddProcess) {
			callbackCreated && callbackCreated(service);
		}
	}, [service])

	useEffect(() => {
		setErrors(apierrors);
	}, [apierrors]);

	const handleChangeData = (e) => {
		const { name, value } = e.target;
		setNewService({ ...newService, [name]: value });
	}

	const form_controls = [
		{
			title: "", items: [
				{ type: "input", name: "summary", label: t('summary'), isEditable: true, val: newService.summary, size: 12, required: true },
				{ type: "datetime", name: "effectiveDate", label: t('effectivedate'), isEditable: true, val: newService.effectiveDate, size: 12, required: true },
				{
					type: "combo", name: "assignee", label: t('assigned'), val: newService.assignee,
					startval: "Unassigned",
					viewLinkPath: "/Admin/AdminUserInfo/",
					getCombo: AdminUserAction.GetComboListByCompanyId, isEditable: true, size: 12
				},
				{ type: "textarea", name: "description", label: t('description'), isEditable: true, val: newService.description, size: 12 },
			]
		}
	]

	const validAdd = () => {
		const errValidate = RayFormValidation(form_controls, newService);
		if (errValidate.length > 0) {
			setErrors(errValidate);
		} else {
			setIsAddProcess(true);
			Create(newService);
		}
	}
	const claimCategory = ({ category, categoryDetail }) => {
		setNewService({
			...newService,
			["category"]: category,
			["categoryDetail"]: categoryDetail
		});
	}

	const [tab, setTab] = useState("error");
	const tabList = [
		{ code: "error", title: t('error') },
		{ code: "product", title: t('product') },
		{ code: "site", title: t('site') },
	]

	return (
		<>
			{isAdmin &&
				<div className="row">
					<div className="col pe-0">
						{referErrorCode._id && <ProductErrorInfo errorCodeInfo={referErrorCode} />}
						{referProduct._id && <ProductSimpleInfo product={referProduct} />}
						{referSite.siteId && <SiteInfo siteId={referSite.siteId} />}
					</div>
					<div className="col mb-2 ps-0">
						<RayForm
							controls={form_controls}
							isForm={true}
							handleChangeData={handleChangeData}
							errors={errors} />
						<div className="section">
							<button className="btn btn-primary btn-block" onClick={() => validAdd()}>{t('add')}</button>
							<button className="btn btn-secondary btn-block" onClick={(e) => history.goBack()}> {t('back')} </button>
						</div>
					</div>
				</div>
			}
			{!isAdmin &&
				<>
					<div className="section mb-2">
						<ul className="nav nav-tabs style2 mt-1 mb-1">
							{tabList.map(x =>
								<li className="nav-item">
									<button key={x.code}
										className={tab == x.code ? "nav-link active" : "nav-link"} onClick={() => { setTab(x.code); }}>
										{x.title}
									</button>
								</li>
							)}
						</ul>
					</div>
					<RayForm
						controls={form_controls}
						isForm={true}
						showAllFields={true}
						handleChangeData={handleChangeData}
						errors={errors} />
					<div className="section">
						<div className="card mt-1">
							<ServiceClaimCategory service={service} callback={(e) => claimCategory(e)} />
						</div>
						{!isAddProcess && <button className="btn btn-block btn-primary" onClick={() => validAdd()}>{t('add')}</button>}
						{isAddProcess && <button className="btn btn-block btn-primary" disabled>Processing...</button>}
					</div>
					{referErrorCode._id && tab == "error" &&
						<ProductErrorInfo errorCodeInfo={referErrorCode} />
					}
					{referProduct._id && tab == "product" &&
						<ProductSimpleInfo product={referProduct} />
					}
					{referSite.siteId && tab == "site" &&
						<SiteInfo siteId={referSite.siteId} showTitle={true} />
					}
				</>
			}
		</>
	);
};

const mapState = (state) => {
	const service = state.ServiceReducer.service;
	const apierrors = state.AlertReducer.errors;
	const errorCode = state.ErrorCodeReducer.errorCode;
	const site = state.SiteReducer.site;
	return { errorCode, site, apierrors, service };
}

const mapDispatch = dispatch => ({
	Create: (data) => dispatch(ServiceAction.CreateService(data)),
	AddUpdate: (info) => dispatch(ServiceAction.AddUpdate(info)),
})

export default connect(mapState, mapDispatch)(ServiceCreateByErrorCode);